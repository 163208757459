import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewSupplier from "../Master/ViewSupplier";
import generatePDF from '../Sales/Proforma';
import generatePDFNew from './PurchaseHistoryInvoice';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const PurchaseHistory = () => {
  const [reportrange, setReportrange] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(10);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleGeneratePDF = (id) => {
    console.log('Generating PDF for ID:', id); 
    generatePDFNew(id);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, 'yyyy-MM-dd'));
      setToDate(format(end, 'yyyy-MM-dd'));
      setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
    }
  };

  const fetchData = async () => 
  {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }

 
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);


  useEffect(() => 
  {
    const table = $('#dsTable').DataTable({
    serverSide: true, 
    ajax: {
      url: `${config.api_url}/ws-purchase-history-list`, 
      type: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: function(d) {
       
        return {
          ...d,
          searchByFromdate: fromDate,
          searchByTodate: toDate,
          searchValue: selectedRole,
        };
      },
      dataSrc: 'data', 
    },
    columns: [
    { data: 'date' },
    { 
      data: null, width: '10%',
      render: (data, type, row) => `<a href="./PurchaseHistoryView/${row.id}">${row.reference_no}</a>`
    },
    { 
      data: 'name', 
      render: (data, type, row) => `<a href="#" class="view-supplier" data-bs-toggle="modal" data-bs-target="#viewsupplier" data-id="${row.supplier_id}">${data}</a>`
    },
    { 
      data: null, width: '10%',
        className: 'text-right',
        render: (data, type, row) => {
          let orderAmt = row.grand_total - row.vat_amount;
          return `<span>${orderAmt.toFixed(2)}</span>`;
        },
        createdCell: function (td) {
          $(td).css("text-align", "right");
        }
      },
      { 
        data: 'vat_amount', width: '10%',
         createdCell: function (td) {
          $(td).css("text-align", "right");
        }
      },

      { 
        data: 'grand_total', width: '10%',
         createdCell: function (td) {
          $(td).css("text-align", "right");
        }
      },
      { 
        data: null, 
         width: '10%',
        render: (data, type, row) => `
        <a href="./PurchaseHistoryView/${row.id}"><i class="fa fa-eye"></i></a>
        <a href="#" class="download-pdf" data-id="${row.id}"><i class="fa fa-download"></i></a>
        `
      }
      ],
      paging: true,
    pageLength: 10, 
    lengthChange: true,
    processing: true,
    language: {
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Next',
        previous: 'Previous',
      },
    },
   
  });

  $('#dsTable tbody').on('click', '.download-pdf', function(event) {
    const id = $(event.currentTarget).data('id');
    handleGeneratePDF(id); 
  });

  return () => {
    table.destroy(); 
  };
}, [fromDate, toDate, selectedRole]);




  return (
    <div>
    <Header />
    <ViewSupplier />
    <div className="breadcrumbs">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <Link to="#"><i className="fa fa-home"></i></Link> &gt; <Link to="dashboard">Dashboard</Link> &gt; <span className="last-crumb">Purchase History</span>
    </div>
    </div>
    </div>
    </div>
    <div className="main-container">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <div className="dashboard">
    <div className='topsearch-area'>
    <form onSubmit={handleSubmit}>
    <div className="row">
    <div className="col-12 col-lg-5 col-sm-5">
    <div className="row">
    <label htmlFor="poId" className="col-sm-5 col-form-label">P.O ID/Supplier</label>
    <div className="col-sm-7">
    <input
    type="text"
    id="poId"
    className="search-area"
    placeholder="Search"
    autoComplete="off"
    value={selectedRole}
    onChange={(e) => setSelectedRole(e.target.value)}
    />
    </div>
    </div>
    </div>
    <div className="col-12 col-lg-4 col-sm-4">
    <div className="row">
    <label htmlFor="dateRange" className="col-sm-3 col-form-label">Date Range</label>
    <div className="col-sm-7">
    <DateRangePicker
    value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
    onChange={handleDateChange}
    format="MM/dd/yyyy"
    />
    </div>
    </div>
    </div>
    </div>
    </form>
    </div>
    </div>

    <div className="vertical-scrl-wrapper common-table purchase-history-tbl" id="style-3">
    <table className="table table-bordered dataTable" id="dsTable">
    <thead>
    <tr>
    <th>Raised on</th>                    
    <th>P.O ID</th>
    <th>Supplier(ID)</th>
    <th>Order Amount</th>
    <th>Tax</th>
    <th>Net</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody></tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
    </div>
    <Footer />
    </div>
    );
  };

  export default PurchaseHistory;
