import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import config from '../Common_Files/Config';

const CustomerSuggestionBox = ({ customer_id, onCustomerChange }) => {

    const accessToken = localStorage.getItem('accessToken');

    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerFilteredOptions, setCustomerFilteredOptions] = useState([]);
    const [customerValue, setCustomerValue] = useState(null); 
    const [customerInputValue, setCustomerInputValue] = useState('');

    const customerHandleChange = (selectedOption) => {
        setCustomerValue(selectedOption);
        if (onCustomerChange) {
            onCustomerChange(selectedOption);  
        }
    };

    const customerHandleInputChange = (inputValue) => {
        setCustomerInputValue(inputValue);
        const newFilteredOptions = customerOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setCustomerFilteredOptions(newFilteredOptions);
    };

    const fetchcategoryList = async () => {
        const apiCategoryUrl = `${config.api_url}/ws-get-customer-list`;
        try {
            const response = await fetch(apiCategoryUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            const formattedOptions = data.customer.map(item => ({
                label: item.company_name,
                value: item.id,
            }));
            setCustomerOptions(formattedOptions);
            setCustomerFilteredOptions(formattedOptions);
        } catch (error) {
            console.error('API failed: error fetching categories', error);
        }
    };

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = '/login';
            return;
        }
        fetchcategoryList();
    }, []);

    useEffect(() => {
        if (customer_id) {
            const selectedCategory = customerOptions.find(option => option.value === customer_id);
            setCustomerValue(selectedCategory || null);
        }
    }, [customer_id, customerOptions]);

    return (
        <Select
            className='promoselect abovedd abovedd-cat'
            isClearable
            onInputChange={customerHandleInputChange}
            onChange={customerHandleChange}
            options={customerFilteredOptions}
            placeholder="Select Customer"
            value={customerValue}
            name="customer"
        />
    );
}

export default CustomerSuggestionBox;
