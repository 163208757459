import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import config from '../Common_Files/Config';

const PurchaseSupplierList = ({ supplier_id, onSupplierChange }) => {

    const accessToken = localStorage.getItem('accessToken');

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [supplierValue, setSupplierValue] = useState(null); 
    const [supplierInputValue, setSupplierInputValue] = useState('');

    const supplierHandleChange = (selectedOption) => {
        setSupplierValue(selectedOption);
        if (onSupplierChange) {
            onSupplierChange(selectedOption);  
        }
    };

    const supplierHandleInputChange = (inputValue) => {
        setSupplierInputValue(inputValue);
        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };

    const fetchcategoryList = async () => {
        const apiCategoryUrl = `${config.api_url}/ws-purchase-order-supplier`;
        try {
            const response = await fetch(apiCategoryUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            const formattedOptions = data.supplier.map(item => ({
                label: item.name,
                value: item.id,
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);
        } catch (error) {
            console.error('API failed: error fetching categories', error);
        }
    };

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = '/login';
            return;
        }
        fetchcategoryList();
    }, []);

    useEffect(() => {
        if (supplier_id) {
            const selectedCategory = supplierOptions.find(option => option.value === supplier_id);
            setSupplierValue(selectedCategory || null);
        }
    }, [supplier_id, supplierOptions]);

    return (
        <Select
            className='abovedd-cat'
            isClearable
            onInputChange={supplierHandleInputChange}
            onChange={supplierHandleChange}
            options={supplierFilteredOptions}
            placeholder="Select supplier"
            value={supplierValue}
            name="supplier" 
            className="promoselect abovedd"
        />
    );
}

export default PurchaseSupplierList;
