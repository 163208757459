import React, { useState, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';

const Change_Permissions = () => {
    const userTableRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [totalUsers, setTotalUsers] = useState('');

    return (
        <div>

            <Header />

            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="Dashboard">Master</a> &gt;
                            <span className="last-crumb">Change Permission</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">


                            <div className="show-order-area dashboard">
                                <div className='role-permission-area'>

                                    <h3>Director ( director ) Role Permissions</h3>

                                    <table className='table table-bordered dataTable'>
                                    <thead>
                                        <tr>
                                            <th>Module Name	</th>
                                            <th>Role</th>
                                        </tr>
                </thead>
                <tbody>
                                        <tr>
                                            <td>Sales</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='NewSales'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="NewSales"> New Sales </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='SalesHistory' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="SalesHistory"> Sales History </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='POS'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="POS"> POS </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='SalesQuickOrder'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="SalesQuickOrder"> Sales Quick Order </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Promotion'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Promotion" > Promotion </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Picking</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='CreatePicking'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="CreatePicking"> Create Picking </label>
                                                        </div>
                                                    </li>


                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='PickingList' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="PickingList"> Picking List </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='PickingHistory' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="PickingHistory"> Picking History </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ShortQTY'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ShortQTY" > Short QTY </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ProformaInvoice' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ProformaInvoice"> Proforma Invoice </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Delivery & Dispatch</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='CreateManifest'  />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="CreateManifest"> Create Manifest </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ManifestList' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ManifestList"> Manifest List </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Trip'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Trip"> Trip </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Returns</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id=''/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="gridCheck1"> &nbsp; </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Purchases</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id=''/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="gridCheck1"> &nbsp;  </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Account</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Push-to-Sage'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Push-to-Sage"> Push-to-Sage </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='AllPushInvoice'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="AllPushInvoice"> All Push Invoice </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ReceivedPayments'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ReceivedPayments"> Received Payments </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='CustomerAccount' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="CustomerAccount"> Customer Account </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='PaymentLedger' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="PaymentLedger"> Payment Ledger </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='MyLedger' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="MyLedger"> My Ledger </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='BankLedger'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="BankLedger"> Bank Ledger </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Master</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Product' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Product"> Product </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ProductsPriceVariation' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ProductsPriceVariation"> Products Price Variation </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Customers' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Customers"> Customers </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Supplier' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Supplier"> Supplier </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Users' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Users"> Users </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='RoleAssign' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="RoleAssign"> Role Assign </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ProductCategories' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ProductCategories"> Product Categories </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Brand' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Brand"> Brand </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Unit' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Unit"> Unit </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='CreditFacility' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="CreditFacility"> Credit Facility </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Adjustments' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Adjustments"> Adjustments </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Stock Take' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Stock Take"> Stock Take </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='OrderProductDiscount' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="OrderProductDiscount"> Order and Product Discount </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Catalogue' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Catalogue"> Catalogue </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Reports</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='CustomernotOrder'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="CustomernotOrder"> Customer not Order </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='DriverReport'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="DriverReport"> Driver Report </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='InventorySales'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="InventorySales"> Inventory Sales </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='MISreport'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="MISreport"> MIS report </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Cross-Off'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Cross-Off"> Cross-Off </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Sales-Rep'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Sales-Rep"> Sales-Rep </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='ProfitLoss'/>
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="ProfitLoss"> Profit and Loss </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>Slider</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <div class="form-check">
                                                            <label class="checkbox">
                                                            <input type="checkbox" name="new_arrival" value="1" id='Show' />
                                                             <span class="default"></span></label>
                                                            <label class="form-check-label" for="Show"> Show </label>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>

                                    <hr />

                                    <div class="row mb-3 padd-l-13px"><div class="col-12 col-lg-12 col-sm-12" ><button type="button" class="popup-innerbtn-common right">Update</button></div></div>


                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Change_Permissions;
