import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import AddSupplierModal from "../Master/Add/AddSupplierModal";
import BankSuggestionBox from "../CommonComp/BankSuggestionBox";
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import { Link, useParams } from 'react-router-dom';

const PayBillsEdit = () => {
    const [suppliers, setSuppliers] = useState([]);  
    const [tableRow, setTableRow] = useState([]);
    const [bills, setBills] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [payingAmount, setPayingAmount] = useState(0);
    const [checkedRows, setCheckedRows] = useState([]);
    const accessToken = localStorage.getItem('accessToken');
    const [bankInfo, setBankInfo] = useState(null); 
    const id = useParams();
    const [formData, setFormData] = useState({
        supplier: '',
        delivery_date: '',
        delivery_id: '',
        details:'',
        transactionRef:''
    });

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
        }

        axios.get(`${config.api_url}/ws-get-suppliers`)
            .then(response => {
                console.log('Suppliers Response:', response.data);
              
                if (response.data.supplier && Array.isArray(response.data.supplier)) {
                    setSuppliers(response.data.supplier);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setSuppliers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });


            axios.get(`${config.api_url}/ws-edit-po-details/${id.id}`)
            .then(response => {
                var inputData = response.data.purchase[0];
                if (response.data.success) {
                    setTableRow(response.data.purchase);
                    setFormData({
                        supplier: id.id,
                        delivery_date: inputData.date ,
                        delivery_id: inputData.bank_id ,
                        details:inputData.details,
                        transactionRef:inputData.transaction_ref
                    });
                } else {
                    console.error('Unexpected data format:', response.data);
                    setTableRow([]); 
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });

    }, []);
    

    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);
    
        // Fetch bills for the selected supplier
        if (supplierId) {
            axios.get(`${config.api_url}/ws-supplier-bills/${supplierId}`) // Adjust the endpoint as necessary
                .then(response => {
                    // Assuming the response structure contains bills directly in an array
                    if (Array.isArray(response.data)) {
                        setBills(response.data);
                    } else {
                        console.error('Unexpected data format for bills:', response.data);
                        setBills([]); // Reset if not an array
                    }
                })
                .catch(error => {
                    console.error('Error fetching bills:', error);
                    setBills([]); // Reset on error
                });
        } else {
            setBills([]); // Clear bills if no supplier is selected
        }
    };
    
    const handlePayingAmountChange = (index, value) => {
        const updatedBills = [...bills];
        updatedBills[index].payingAmount = value;
        setBills(updatedBills);
    };

    const fetchData = async () => {};

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBankChange = (selectedBank) => {
        setBankInfo(selectedBank); 
    };

    const handlePayChange = (index, value) => {
        const newTableRow = [...tableRow];
        newTableRow[index].pay = parseFloat(value) || 0;
        setTableRow(newTableRow);

        calculateTotals(newTableRow);
    };

    const calculateTotals = (rows, selectedRows = checkedRows) => {
        const totalPay = rows
            .filter((_, idx) => selectedRows.includes(idx))
            .reduce((total, row) => total + (row.pay || 0), 0);
        setPayingAmount(totalPay);

        const totalBill = rows
            .filter((_, idx) => selectedRows.includes(idx))
            .reduce((sum, row) => sum + (parseFloat(row.value) || 0), 0);
        setBills(totalBill);
    };

    const handleCheckboxChange = (index) => {
        setCheckedRows((prevCheckedRows) => {
            const newCheckedRows = prevCheckedRows.includes(index)
                ? prevCheckedRows.filter((rowIndex) => rowIndex !== index) 
                : [...prevCheckedRows, index]; 

            calculateTotals(tableRow, newCheckedRows);
            return newCheckedRows;
        });
    };

    const handleSubmit = async (event) =>
        {    
        //    event.preventDefault();
        //    const formData = new FormData();
        //    const supplier = supplierInfo ? supplierInfo.value : '';
        //    const dDate = deliveryDate ? deliveryDate : '';
        //    const bank = bankInfo ? bankInfo.value : '';
        //    const detailInfo = details ? details : '';
        //    const transactionInfo = transactionRef ? transactionRef : '';
   
        //    formData.append('supplier_id', supplier);
        //    formData.append('date', dDate);
        //    formData.append('bank_id', bank);
        //    formData.append('details', detailInfo);
        //    formData.append('transaction_ref', transactionInfo);
        //    formData.append('paying_amount', payingAmount);
        //    formData.append('balance', payingAmount - bills);
   
        //    // Gather data for the checked rows
        //    const selectedRowsData = tableRow
        //        .filter((_, idx) => checkedRows.includes(idx))
        //        .map((row) => ({
        //            purchase_id: row.purchase_id,
        //            pay: row.pay || 0,
        //        }));
   
        //    formData.append('selected_rows[]', JSON.stringify(selectedRowsData));
   
        //    // Send the form data to the backend
        //    const apiUrl = `${config.api_url}/ws-submit-purchase-payment`;
   
        //    try {
        //        const response = await fetch(apiUrl, {
        //            method: 'POST',
        //            headers: {
        //                authorization: `Bearer ${accessToken}`,
        //            },
        //            body: formData,
        //        });
   
        //        if (!response.ok) {
        //            throw new Error('Failed to submit payment data');
        //        }
   
        //        const data = await response.json();
        //        console.log('Submission successful:', data);
        //    } catch (error) {
        //        console.error('Error submitting payment data:', error);
        //    }
       };

    return (
        <div>
            <Header />
            <AddSupplierModal fetchData={fetchData} />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Pay Bills Edit</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left' }}>
                                        <div className="newsalesorderform">
                                            <div style={{ float: "right", width: "100%" }}>
                                                <a href="#" className="create-catalogue-btn" data-bs-toggle="modal"
                                                data-bs-target="#add-supplier" ><i className='fa fa-plus'></i>&nbsp;New Supplier
                                                </a>
                                            </div>
                                            <hr />
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="supplier" className="col-form-label">Select Supplier * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                       <select className="form-control" id="supplier" name='supplier' value={formData.supplier} onChange={(e) =>{handleSupplierChange(e);
                                                        handleChange(e);
                                                       }}>
                                                        
                                                        <option value="">Select Supplier</option>
                                                        {suppliers.map(supplier => (
                                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                        ))}
                                                    </select>
                                                    </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="delivery_date" className="col-form-label">Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" value={formData.delivery_date} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bank" className="col-form-label">Bank </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <BankSuggestionBox onBankChange={handleBankChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="details" className="col-form-label">Details </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='details' value={formData.details} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="transaction_ref" className="col-form-label">Transaction Ref </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='transactionRef' value={formData.transactionRef} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="paying_amount" className="col-form-label">Paying Amount</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='payingAmt' disabled value={payingAmount} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="balance" className="col-form-label">Balance</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='balance' disabled value={payingAmount - bills} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="verical-scrl-wrapper common-table paybill-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>ID</th>
                                                        <th>Raised On</th>
                                                        <th>Type</th>
                                                        <th>Bill No</th>
                                                        <th>Job Ref</th>
                                                        <th>P.O ID</th>
                                                        <th>Supplier (ID)</th>
                                                        <th>Value</th>
                                                        <th>Balance</th>
                                                        <th>Pay</th>
                                                        <th>Due Date</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableRow.map((bill, index) => (
                                                        <tr key={bill.id}>
                                                             <td>
                                                                <label className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() => handleCheckboxChange(index)}
                                                                    />
                                                                    <span className="default"></span>
                                                                </label>
                                                            </td>
                                                            <td><a href='#'>{bill.id}</a></td>
                                                            <td>{bill.created_date}</td>

                                                            <td>{bill.type}</td>
                                                            <td>{bill.bill_no}</td>
                                                            <td>{bill.job_ref}</td>
                                                            <td><a href='#'>{bill.reference_no}</a></td>
                                                            <td><a href='#'>{bill.name}</a></td>
                                                            <td>{bill.value}</td>
                                                            <td>{bill.balance}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    name="pay[]"
                                                                    className="form-control qtyinputbox no-arrows"
                                                                    defaultValue={bill.paying_amount || ''}
                                                                    style={{ textAlign: 'right' }}
                                                                    onChange={(e) => handlePayChange(index, e.target.value)}
                                                                    placeholder="Enter Amount"
                                                                />
                                                            </td>
                                                            <td>{bill.due_date}</td>
                                                            <td>{bill.created_by}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button type="button" onClick={handleSubmit} className="saveclose">Save & Close</button>
                                            <button type="button" onClick={handleSubmit} className="savenew">Save & New</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default PayBillsEdit;
