import React, { useState, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';

import ViewSupplier from "../Master/ViewSupplier";
import AddSupplierModal from "../Master/Add/AddSupplierModal";

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import AddRole from './Add_Role';

const Group_Permissions = () => {
  const userTableRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [totalUsers, setTotalUsers] = useState('');
  

  return (
    <div>
      <AddRole showModal={showModal} setShowModal={setShowModal} />
      

      <Header />

      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="Dashboard">Master</a> &gt;
              <span className="last-crumb">Users</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className='change-permission-area'>
                <div className="row">
                  <div className="col-12 col-lg-4 col-sm-4"></div>
                  <div className="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right", width: "200px", marginBottom: '15px' }}>
                      <button
                        className="create-catalogue-btn" 
                        onClick={() => setShowModal(true)}
                        style={{ marginLeft: "10px" }}
                      >
                        Add Role
                      </button>
                    </div>
                  </div>
                </div>

               


                <div className="show-order-area dashboard">
                  <div className="verical-scrl-wrapper common-table users-table" id="style-3">
                    <table className="table table-bordered dataTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>ID</th>
                          <th>Role Name</th>
                          <th>Role Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>
			              <label class="checkbox">
			                <input type="checkbox" name="val[]" value="194" class="dataTableCheckbox" />
			                <span class="default"></span>
			              </label>
			            </td>
                          <td>ID</td>
                          <td>Role Name</td>
                          <td>Role Description</td>
                          


              <td>
                <div class="btn-group">
                    <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                    <ul class="dropdown-menu dropdown-menu-arrow" >
                    <li><a class="deductionbtn" href="/Change_Permissions">Change Permission</a></li>
                  <li><a class="applybtn" href="#" >Edit</a></li>
                  <li><a class="applybtn" href="#" >Delete</a></li>
                    </ul>
                </div></td>


                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Group_Permissions;
