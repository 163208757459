import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import ViewProduct from "../Master/ViewProduct";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';



const Products = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [productData, setProductData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [editProduct, setEditProduct] = useState([]);
  const [vatData, setVatData] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const modalRef = useRef(null); // Reference to the modal
  const updateModalRef = useRef(null); // Reference to the modal
  const tableRef = useRef(null); // Reference to the table element
  const accessToken = localStorage.getItem('accessToken');

   

  useEffect(() => {
    fetchWarehouseData();
  }, []);

  const fetchData = async () => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }
    const searchValue = $('#product_name').val();
    const location_name = $('#location_name').val();
    const warehouse_id = $('#warehouse_id').val();
    const stock_type = $('#stock_type').val();

    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("searchValue", searchValue);
    formData.append("location_name", location_name);
    formData.append("warehouse_id", warehouse_id);
    formData.append("stock_type", stock_type);

    const apiUrl = `${config.api_url}/get-products-list`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData,
      });
      const data = await response.json();
      setProductData(data.products);
    } catch (error) {
      console.error("Error fetching new sales data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchSupplierData = async () => {
    const apiUrl = `${config.api_url}/ws-get-suppliers`;

    try {
      const response = await axios.post(apiUrl, null, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const data = response.data;
      setSupplierData(data.supplier);
    } catch (error) {
      console.error("Error fetching suppliers data:", error);
    }
  };


  const fetchCategoryData = async () => {
    const apiUrl = `${config.api_url}/ws-get-category`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setCategoryData(data.category);
    } catch (error) {
      console.error("Error fetching categories data:", error);
    }
  };

  const updateProductForm = async () => {
    const formElement = document.getElementById('editProductForm');
    const formData = new FormData(formElement);
    var product_desc = $('#product_desc').val();
    var min_threshold_qty = $('#min_threshold_qty').val();
    var return_check = 0;
    if (product_desc === '') 
      {
        $('#edit_product_desc_error').show();
        return_check = 1;
      } else {
        $('#edit_product_desc_error').hide();
        
      }

      if (min_threshold_qty === '') 
        {
          $('#min_threshold_qty_error').show();
          return_check=1;
        } else {
          $('#min_threshold_qty_error').hide();
        }

        if(return_check == 1)
        {
          return;
        }


    let a = formData.get("status");
    formData.set("status", formData.get("status") === "on" ? 1 : 0);
   
    try {
      const response = await fetch(`${config.api_url}/ws-update-product`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData,
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire({
          title: 'Success!',
          text: 'Product updated successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        });

        // Close the modal using the reference
        const bsModal = window.bootstrap.Modal.getInstance(updateModalRef.current);
        if (bsModal) bsModal.hide();

        fetchData();
      }

    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  useEffect(() => {
    const table = $("#productListTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: productData,
      columnDefs: [
        { orderable: false, targets: '_all' }
      ],
      columns: [
        {
          data: "image",
          width: "5%", // 5% of the table width
          render: function (data, type, row) {
            return `<img src="${row.image}" name="prev_image" id="prev_image" alt="Product Image" width="50px" height="50px" />`;
          }
        },
        { data: "code", width: "5%" }, // 10% of the table width
        { data: "product_desc" }, // 15% of the table width
        { data: "pack", width: "5%" }, // 5% of the table width
        { data: "size", width: "5%" }, // 5% of the table width
        { data: "unit_barcode", width: "5%" }, // 10% of the table width
        { data: "case_barcode", width: "5%" }, // 10% of the table width
        { data: "pallet_qty", width: "5%" }, // 5% of the table width
        { data: "layer_qty", width: "5%" }, // 5% of the table width
        { data: "gross_weight", width: "5%" },
        { data: "net_weight", width: "5%" }, // 5% of the table width
        { data: "cbm", width: "5%" }, // 5% of the table width
        { data: "category", width: "5%" }, // 10% of the table width
        { data: "price", width: "5%" }, // 5% of the table width
        { data: "freestock", width: "5%" }, // 5% of the table width
        { data: "onso", width: "5%" },
        { data: "quantity", width: "5%" }, // 5% of the table width
        {
          data: null,
          width: "6%", // 10% of the table width
          render: function (data, type, row) {
            let status = row.status == 1 ? 'Active' : 'Inactive';
            return status;
          }
        },
        {
          data: null,
          width: "6%", // 10% of the table width
          render: function (data, type, row) {
            return `
                <a href="#" class="applybtn" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#viewproduct">
                <i class="fa fa-eye"></i> 
                </a>
                <a href="#" class="deductionbtn" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit-product">
                <i class="fa fa-edit"></i> 
                </a>`;
          }
        },
      ],


    });
    return () => {
      table.destroy();
    };
  }, [productData]);

  useEffect(() => {
    $('#productListTable tbody').off('click', '.applybtn');
    $('#productListTable tbody').on('click', '.applybtn', function () {
      $('#freestock').empty();
      $('#stock').empty();
      $('#on_so').empty();
      $('#allocation').empty();
      $('#onpf').empty();
      $('#onpo').empty();
      const product_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', product_id);
      document.getElementById('viewProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $('#code').val(data.products[0].product_desc);
          $('#packValue').val(data.products[0].pack);
          $('#vat').val(data.products[0].vat);
          $('#weight').val(data.products[0].gross_weight);
          $('#supplier').val(data.products[0].supplier_name);
          $('#description').val(data.products[0].product_desc);
          $('#sizeValue').val(data.products[0].size);
          $('#barcode').val(data.products[0].unit_barcode);
          $('#view_case_barcode').val(data.products[0].case_barcode);
          $('#pallet_qty').val(data.products[0].pallet_qty);
          $('#case_price').val(data.products[0].price);
          $("#product_status").html(data.products[0].status != 0
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
          );
          $('#viewcbm').val(data.products[0].cbm);
          $('#brandname').val(data.products[0].brand);
          $('#categooryName').val(data.products[0].category);
          $('#editnetWeight').val(data.products[0].net_weight);
          $('#editgrossWeight').val(data.products[0].gross_weight);
          $('#freestock').append(data.freestock == null ? 0 : data.freestock);
          $('#stock').append(parseInt(data.products[0].quantity));
          $('#on_so').append(data.onso == null ? 0 : data.onso);
          $('#allocation').append(data.allocation == null ? 0 : data.allocation);
          $('#onpf').append(data.pf == null ? 0 : data.pf);
          $('#onpo').append(data.on_purchase == null ? 0 : data.on_purchase);
        } 
        catch (error) 
        {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $('#productListTable tbody').off('click', '.applybtn');
    };
  }, []);

  useEffect(() => 
  {
    $('#productListTable tbody').off('click', '.deductionbtn');
    $('#productListTable tbody').on('click', '.deductionbtn', function () {
      $('#edit_product_desc_error').hide();
      $('#min_threshold_qty_error').hide();
      fetchBrandData();
      fetchSupplierData();
      fetchCategoryData();
      fetchVatData();
      const supplier_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', supplier_id);
      document.getElementById('editProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $('#product_desc').val(data.products[0].product_desc);
          $('#category_id').val(data.products[0].category_id).change();
          $('#supplier_id').val(data.products[0].supplier_id).change();
          $('#brand_id').val(data.products[0].brand_id).change();
          $('#vat_id').val(data.products[0].vat_id).change();
          $('#pack').val(data.products[0].pack);
          $('#size').val(data.products[0].size);
          $('#pallet_quantity').val(data.products[0].pallet_qty);
          $('#layer_quantity').val(data.products[0].layer_qty);
          $('#unit_barcode').val(data.products[0].unit_barcode);
          $('#case_barcode').val(data.products[0].case_barcode);
          $('#min_threshold_qty').val(data.products[0].min_threshold_qty);
          $('#case_weight').val(data.products[0].weight);
          $('#length').val(data.products[0].length);
          $('#length_unit').val(data.products[0].length_unit).change();
          $('#width').val(data.products[0].width);
          $('#width_unit').val(data.products[0].width_unit).change();
          $('#height').val(data.products[0].height);
          $('#height_unit').val(data.products[0].height_unit).change();
          $('#cost_price').val(data.products[0].cost);
          $('#sell_price').val(data.products[0].price);
          $('#product_id').val(data.products[0].id);
          $('#grossWeight').val(data.products[0].gross_weight);
          $('#netWeight').val(data.products[0].net_weight);
          $('#cbm').val(data.products[0].cbm);
          $('#prev_image').attr('src', data.products[0].image);
          $('#oldPdtImg').val(data.products[0].image);
          const url = '';
          // $('#product_image').attr('src', '"/img/headphone.jpg');
          if (data.products[0].status == "1") {
            $('#status').prop('checked', true);
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $('#productListTable tbody').off('click', '.deductionbtn');
    };
  }, []);

  const submitNewProductForm = async () => {
    const formElement = document.getElementById('addProductForm');
    const formData = new FormData(formElement);

    try {
      const response = await fetch(`${config.api_url}/ws-add-product`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData,
      });

      const result = await response.json();
      formElement.reset();

      if (result.success) {
        Swal.fire({
          title: 'Success!',
          text: 'Product added successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        });

        // Close the modal using the reference
        const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
        if (bsModal) bsModal.hide();
        fetchData();

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred while adding the product.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false
        });
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const getEditModal = () => {
    fetchSupplierData();
  }

  const fetchBrandData = async () => {
    const apiUrl = `${config.api_url}/ws-get-brand`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setBrandData(data.brands);

    } catch (error) {
      console.error("Error fetching brands data:", error);
    }
  };

  const fetchWarehouseData = async () => {
    const apiUrl = `${config.api_url}/ws-get-warehouse`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setWarehouse(data.warehouse);

    } catch (error) {
      console.error("Error fetching warehouse data:", error);
    }
  };

  const fetchVatData = async () => {
    const apiUrl = `${config.api_url}/ws-get-vat`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setVatData(data.vat);

    } catch (error) {
      console.error("Error fetching vat data:", error);
    }
  };

  const AddProduct = () => {
    fetchBrandData();
    fetchSupplierData();
    fetchCategoryData();
    fetchVatData();
  }

 const getCBM = () => {
  const unitConversionToMM = {
    'mm': 1,         // 1 mm = 1 mm
    'cm': 10,        // 1 cm = 10 mm
    'in': 25.4,      // 1 inch = 25.4 mm
  };

  let d = 0; // This will hold the volume in mm³

  // Get values and convert to millimeters
  const a = parseFloat($('#add_width').val());
  const b = parseFloat($('#add_height').val());
  const c = parseFloat($('#add_length').val()); // Corrected spelling here

  const e = parseFloat($('#length').val());
  const f = parseFloat($('#width').val());
  const g = parseFloat($('#height').val());

  if (a && b && c) {
    const a_length_unit = $('#length_unit').val();
    const a_width_unit = $('#width_unit').val();
    const a_height_unit = $('#height_unit').val();

    // Convert to millimeters
    const lengthInMM = c * unitConversionToMM[a_length_unit];
    const widthInMM = a * unitConversionToMM[a_width_unit];
    const heightInMM = b * unitConversionToMM[a_height_unit];

    // Calculate volume in cubic meters
    d = (lengthInMM * widthInMM * heightInMM) / 1e9; // Convert to m³

    console.log(d);
    $('#add_cbm').val((d).toFixed(6)); // Display up to 6 decimal places
  } else if (e && f && g) {
    const e_length_unit = $('#edit_length_unit').val();
    const e_width_unit = $('#edit_width_unit').val();
    const e_height_unit = $('#edit_height_unit').val();

    // Convert to millimeters
    const lengthInMM = e * unitConversionToMM[e_length_unit];
    const widthInMM = f * unitConversionToMM[e_width_unit];
    const heightInMM = g * unitConversionToMM[e_height_unit];

    // Calculate volume in cubic meters
    d = (lengthInMM * widthInMM * heightInMM) / 1e9; // Convert to m³

     console.log(d);
    $('#cbm').val((d).toFixed(6)); // Display up to 6 decimal places
  }
};







  return (
    <div>

      <div className="modal fade" id="add-product" tabIndex="-1" ref={modalRef} >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form id="addProductForm">
                  <div className="row mb-3 padd-l-13px">


                    <div class="col-sm-6"><label htmlFor="desc" className="col-sm-6 col-form-label">Product Description *</label>
                      <input type="text" className="form-control" name="product_desc" />
                    </div>


                    <div class="col-sm-6"> <label htmlFor="compType" className="col-sm-6 col-form-label">Category</label>
                      <select name="category_id" className="form-select orgselect" defaultValue=''>
                        <option value="" disabled>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Supplier</label>
                      <select name="supplier_id" className="form-select orgselect" required="" defaultValue=''>
                        <option value="" disabled>Select Supplier</option>
                        {supplierData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Brand</label>
                      <select name="brand_id" id="brandlist" className="form-select orgselect" required="" defaultValue=''>
                        <option value="" disabled>Select Brand</option>
                        {brandData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div class="col-sm-6"> <label htmlFor="displayName" className="col-sm-6 col-form-label">VAT % *</label>
                      <select name="vat_id" className="form-select orgselect">
                        {vatData.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Pack</label>
                      <input type="text" className="form-control" name="pack" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Size</label>
                      <input type="text" className="form-control" name="size" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Pallet Quantity</label>
                      <input type="text" className="form-control" name="pallet_quantity" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Layer Quantity</label>
                      <input type="text" className="form-control" name="layer_quantity" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Unit Barcode</label>
                      <input type="text" className="form-control" name="unit_barcode" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Case Barcode</label>
                      <input type="text" className="form-control" name="case_barcode" />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="displayName" className="col-sm-6 col-form-label">Minimum Threshold Quantity </label>
                      <input type="text" className="form-control" name="min_threshold_qty" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Gross Weight</label>
                      <input type="text" className="form-control" name="gross_weight" placeholder="in Kg" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Net Weight</label>
                      <input type="text" className="form-control" name="net_weight" placeholder="in Kg" />
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Length</label>
                      <input type="text" className="form-control smallinput" onChange={getCBM} name="length" id="add_length" />
                      <select name="length_unit" id="length_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Width</label>
                      <input type="text" className="form-control smallinput" name="width" onChange={getCBM} id="add_width" />
                      <select name="width_unit" id="width_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Height</label>
                      <input type="text" className="form-control smallinput" onChange={getCBM} name="height" id="add_height" />
                      <select name="height_unit" id="height_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Cost Price</label>
                      <input type="text" className="form-control" name="cost_price" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Sell Price</label>
                      <input type="text" className="form-control" name="sell_price" />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="inputEmail5" className="col-form-label">CBM (Cubic meter)</label>
                      <input type="text" className="form-control" name="cbm" id="add_cbm" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label">Status</label>
                    <div className="col-sm-3">
                      <label className="switch">
                        <input className="switch-input" type="checkbox" name="status" defaultChecked />
                        <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                      </label>
                    </div>
                    <label htmlFor="compType" className="col-sm-3 col-form-label">Product Image</label>
                    <div className="col-sm-3">
                      <input className="form-control" type="file" name="product_image" />
                    </div>
                  </div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <Link to="#" onClick={submitNewProductForm} className="popup-innerbtn-common right">Save</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="edit-product" tabIndex="-1" ref={updateModalRef} >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form id="editProductForm">
                  <div className="row mb-3 padd-l-13px">
                    <div class="col-sm-6">
                      <input type="hidden" className="form-control" name="product_id" id="product_id" />
                      <label htmlFor="desc" className="col-sm-6 col-form-label">Product Description *</label>
                      <input type="text" className="form-control" name="product_desc" id="product_desc" />
                      <span id="edit_product_desc_error" style={{ color: 'red', display: 'none' }}> Please enter product description </span>
                    </div>
                    <div class="col-sm-6">
                      <label htmlFor="compType" className="col-sm-6 col-form-label">Category</label>
                      <select name="category_id" id="category_id" className="form-select orgselect" defaultValue=''>
                        <option value="" disabled>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Supplier</label>
                      <select name="supplier_id" id="supplier_id" className="form-select orgselect" required="" defaultValue=''>
                        <option value="" disabled>Select Supplier</option>
                        {supplierData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Brand</label>
                      <select name="brand_id" id="brand_id" className="form-select orgselect" required="" defaultValue=''>
                        <option value="" disabled>Select Brand</option>
                        {brandData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>

                    <div class="col-sm-6">
                      <label htmlFor="displayName" className="col-sm-6 col-form-label">VAT % *</label>
                      <select name="vat_id" id="vat_id" className="form-select orgselect" defaultValue=''>
                        <option value="" disabled>Select VAT</option>
                        {vatData.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Pack</label>
                      <input type="text" className="form-control" name="pack" id="pack" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Size</label>
                      <input type="text" className="form-control" name="size" id="size" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Pallet Quantity</label>
                      <input type="text" className="form-control" name="pallet_quantity" id="pallet_quantity" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Layer Quantity</label>
                      <input type="text" className="form-control" name="layer_quantity" id="layer_quantity" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Unit Barcode</label>
                      <input type="text" className="form-control" name="unit_barcode" id="unit_barcode" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Case Barcode</label>
                      <input type="text" className="form-control" name="case_barcode" id="case_barcode" />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="displayName" className="col-sm-6 col-form-label">Minimum Threshold Quantity *</label>
                      <input type="text" className="form-control" name="min_threshold_qty" id="min_threshold_qty" />
                      <span id="min_threshold_qty_error" style={{ color: 'red', display: 'none' }}> Please enter minimum threshold quantity </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Gross Weight</label>
                      <input type="text" className="form-control" name="gross_weight" placeholder="in Kg" id="grossWeight" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Net Weight</label>
                      <input type="text" className="form-control" name="net_weight" placeholder="in Kg" id="netWeight" />
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Length</label>
                      <input type="text" className="form-control smallinput" onChange={getCBM} name="length" id="length" />
                      <select name="length_unit" id="edit_length_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Width</label>
                      <input type="text" className="form-control smallinput" onChange={getCBM} name="width" id="width" />
                      <select name="width_unit" id="edit_width_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="inputState" className="col-form-label" style={{ width: '100%' }}>Height</label>
                      <input type="text" className="form-control smallinput" onChange={getCBM} name="height" id="height" />
                      <select name="height_unit" id="edit_height_unit" onChange={getCBM} className="form-select smallselect">
                        <option value='mm'>MM</option>
                        <option value='cm'>CM</option>
                        <option value='in'>IN</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Cost Price</label>
                      <input type="text" className="form-control" name="cost_price" id="cost_price" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="inputEmail5" className="col-form-label">Sell Price</label>
                      <input type="text" className="form-control" name="sell_price" id="sell_price" />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="inputEmail5" className="col-form-label">CBM (Cubic meter)</label>
                      <input type="text" className="form-control" name="cbm" id="cbm" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label">Status</label>
                    <div className="col-sm-3">
                      <label className="switch">
                        <input className="switch-input" type="checkbox" name="status" id="status" />
                        <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                      </label>
                    </div>
                    <label htmlFor="compType" className="col-sm-2 col-form-label">Product Image</label>
                    <div className="col-sm-4">
                      <input className="form-control" type="file" name="product_image" id="product_image" value="" />
                      <img name="prev_image" id="prev_image" width='100px' height='100px' title="Product Image" />
                      <input type="hidden" className="form-control" name="oldPdtImg" id="oldPdtImg" />
                    </div>
                  </div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <Link to="#" onClick={updateProductForm} className="popup-innerbtn-common right">Update</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <ViewProduct />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="Dashboard">Master</a> &gt;{" "}
              <span className="last-crumb">Product</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="dashform">
                  <div class="topsearch-area">
                    <div class="row">
                      <div class="col-12 col-lg-4 col-sm-4">
                        <div class="row">
                        <label for="inputText" class="col-sm-5 col-form-label">Search Code / Description</label>
                        <div class="col-sm-7">
                          <input type="text" className="search-area form-control" autoComplete='off' onChange={fetchData} placeholder="Search" name="product_name" id="product_name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-sm-3"><select name="warehouse_id" onChange={fetchData} id="warehouse_id" className="form-select" defaultValue=''>
                        <option value="" disabled>Select Warehouse</option>
                        <option value="all">All</option>
                        {warehouse.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </select></div>
                      <div class="col-12 col-lg-3 col-sm-3"> 
                      <div class="row">
                      <label for="inputText" class="col-sm-4 col-form-label">Stock Type</label>
                      <div class="col-sm-8">
                        <select
                          name="stock_type"
                          id="stock_type"
                          onChange={fetchData}
                          className="form-select"
                          aria-label="Default select example">
                          <option value="all">All</option>
                          <option value="free_stock">Free Stock </option>
                          <option value='on_po' >On P.O.</option>
                          <option value='on_so' >On S.O.</option>
                        </select>
                        </div>
                        </div>
                        </div>
                      <div class="col-12 col-lg-2 col-sm-2">
                        <div style={{ float: "right" }}>
                          <Link className="create-catalogue-btn" onClick={AddProduct} data-bs-toggle="modal"
                            data-bs-target="#add-product" > Add New Product </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="verical-scrl-wrapper common-table product-table" id="style-3" >
                    <table className="table table-bordered dataTable" id="productListTable" ref={tableRef} >
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Code</th>
                          <th>Product Description</th>
                          <th>Pack</th>
                          <th>Size</th>
                          <th>Unit Barcode</th>
                          <th>Case Barcode</th>
                          <th>Pallet Qty</th>
                          <th>Layer Qty</th>
                          <th>Gross Wt</th>
                          <th>Net Wt</th>
                          <th>CBM</th>
                          <th>Category</th>
                          <th>Sell price</th>
                          <th>F. Stk</th>
                          <th>On SO</th>
                          <th>Stock</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
