import React,{useRef} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../Common_Files/Config";

const AddBrandModal = ({ initializeDataTable }) => {
  const modalRef = useRef(null);
  const validationSchema = Yup.object({
    name: Yup.string().required("Category Name is required"),
    status: Yup.boolean(),
  });

  const handleAddBrand = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("status", values.status ? 1 : 0);

      const response = await axios.post(
        `${config.api_url}/ws-add-brand`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        if (modalRef.current) {
          const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
          if (bsModal) bsModal.hide();
        }

        Swal.fire({
          title: "Success!",
          text: "Brand added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          initializeDataTable();
          resetForm(); // Reset form after submission
        });
      } 
      else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "An error occurred while adding a brand.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while adding a brand.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="modal fade" id="add_brand" tabIndex="-1" ref={modalRef} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Brand</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="warehousemaster-area">
              <Formik
                initialValues={{ name: "", status: true }}
                validationSchema={validationSchema}
                onSubmit={handleAddBrand}
              >
                {({ isSubmitting }) => (
                  <Form id="addNewBrand">
                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="name" className="col-sm-6 col-form-label">Brand Name *</label>
                      <div className="col-sm-6">
                        <Field type="text" className="form-control" name="name" />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="status" className="col-sm-6 col-form-label">Status</label>
                      <div className="col-sm-6">
                      <label class="switch">
                            <input class="switch-input" type="checkbox" name="status" defaultChecked />
                            <span  class="switch-label" data-on="Active"  data-off="Inactive" ></span>{" "}
                            <span class="switch-handle"></span>
                          </label>
                      </div>
                    </div>

                    <hr />
                    <div className="row padd-l-13px">
                      <div className="col-sm-12">
                        <button type="submit" className="popup-innerbtn-common right" disabled={isSubmitting}>
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBrandModal;
