import React, { useState, useEffect } from 'react';
import { Modal, Table, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import config from '../Common_Files/Config';
import Swal from 'sweetalert2';

const ProductListModel = ({ onViewPastPurchase, modalData = false,setTableRows,tableRows }) => {
  const [productBatch, setProductBatch] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showPastPurchaseModal, setShowPastPurchaseModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(new Set());

  const accessToken = localStorage.getItem('accessToken');

  const handleShowPastPurchaseModal = () => setShowPastPurchaseModal(true);
  const handleClosePastPurchaseModal = () => setShowPastPurchaseModal(false);

  useEffect(() => {
    if (modalData) {
      setProductBatch(modalData);
      setFilteredProducts(modalData); // Initialize with all products
    }
  }, [modalData]);

  useEffect(() => {
    if (onViewPastPurchase) {
      onViewPastPurchase(handleShowPastPurchaseModal);
    }
  }, [onViewPastPurchase]);

  const handleSearch = async (event) => 
  {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);


    if (!query) {
      setFilteredProducts(productBatch);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("searchValue", query); 
      const apiUrl = `${config.api_url}/ws-get-products`;
      const response = await axios.post(apiUrl, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });


      setFilteredProducts(response.data.products || []); 
    } catch (error) {
      console.error("Error fetching search results:", error);  
      setFilteredProducts([]);
    }
  };


  const toggleSelection = (id) => {
    const updatedSelection = new Set(selectedProducts);
    if (updatedSelection.has(id)) {
      updatedSelection.delete(id);
    } else {
      updatedSelection.add(id);
    }
    setSelectedProducts(updatedSelection);
  };

  const handleSubmit = async () => 
  {
    const selectedArray = Array.from(selectedProducts);



    for (const id of selectedArray) {
      const formData = new FormData();
      formData.append("id", id);

      const apiUrl = `${config.api_url}/ws-get-products-by-id`;

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        const productData = response.data.products[0];



      // Check if the product already exists
      setTableRows(prevRows => {
        if (!Array.isArray(prevRows)) {
          console.error("prevRows is not an array:", prevRows);
          return []; // Return an empty array to prevent further errors
        }

        const existingRow = prevRows.find(element => element.id === productData.id);
        if (existingRow) {
          Swal.fire({
            title: "Error!",
            text: "Product already exists",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return prevRows; // Return previous rows if exists
        }

        // Create new row
        const newRow = {     



          id:productData.id,
          code:productData.code,
          product_desc:productData.product_desc,
          pack: productData.pack,
          size: productData.size,
          price: productData.price,
          cost: productData.cost,
          quantity: productData.quantity,
          taxrate: productData.rate,
          grossWeight: productData.gross_weight,
          netWeight:productData.net_weight,
          cbm: productData.cbm,
          vat: parseFloat(productData.vat),
          value: 0,
          profit: (productData.price-productData.cost),
          batch_id: 0,
          pdtqty : 0,
          purchase_id : 0,

          quantity : productData.quantity,        

          receivedQty : productData.receivedQty ? productData.receivedQty : 0 ,
          mappedPO : productData.mappedPO ? productData.mappedPO : 0,
          Onpo :  response.data.OnPO.totalPO ?  response.data.OnPO.totalPO : 0, 

          fstock : ( response.data.OnPO.totalPO + productData.quantity),
          allocation :  response.data.OnPO.allocation ?  response.data.OnPO.allocation : 0,
          freestock : (response.data.freestock !== undefined && response.data.freestock !== null) ? parseInt(response.data.freestock) + parseInt(productData.quantity) : 0,
          freestockcal:(response.data.freestock !== undefined && response.data.freestock !== null) ? parseInt(response.data.freestock) + parseInt(productData.quantity) : 0,    
          onpurchase : response.data.OnPO.totalPO ? response.data.OnPO.totalPO : 0, 
          
        };



        return [...prevRows, newRow]; // Add the new row to the existing rows
      });

    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  }

  // Clear selections
  setSelectedProducts(new Set());
  handleClosePastPurchaseModal(); // Close the modal after submitting
};


return (
  <div>
  <Modal show={showPastPurchaseModal} onHide={handleClosePastPurchaseModal} size="lg">
  <Modal.Header closeButton> 
  Search Product    
  </Modal.Header>
  <Modal.Body>
  <Form.Control
  type="text"
  placeholder="Search (Code/Description/Unit Barcode)"
  value={searchQuery}
  onChange={handleSearch}
  className="mb-3"
  />
  <div className="partialbox">  
  <Table striped bordered hover className="dataTable">
  <thead>
  <tr>
  <th>Select</th>
  <th>Code</th>
  <th>Description</th>
  <th>Pk</th>
  <th>Size</th>
  <th>Unit Barcode</th>
  <th>Stock</th>
  <th>F. Stk</th>
  </tr>
  </thead>
  <tbody>
  {filteredProducts.map((item, index) => (
    <tr key={index}>
    <td>
    <Form.Check
    type="checkbox"
    checked={selectedProducts.has(item.id)}
    onChange={() => toggleSelection(item.id)}
    />
    </td>
    <td>{item.code}</td>
    <td>{item.product_desc}</td>
    <td>{item.pack}</td>
    <td>{item.size}</td>
    <td>{item.unit_barcode}</td>
    <td>{item.quantity}</td>
    <td>{item.free_stock}</td>
    </tr>
    ))}
    </tbody>
    </Table>
    </div>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={handleSubmit}>
    Submit
    </Button>
    </Modal.Footer>
    </Modal>
    </div>
    );
  };

  export default ProductListModel;
