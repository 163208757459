

import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import CustomerSuggestionBox from '../CommonComp/CustomerSuggestionBox';
import BankSuggestionBox from '../CommonComp/BankSuggestionBox';
import ViewCustomer from "../Master/ViewCustomer"

const ReceivePayment = () => {
    
    const [bills, setBills] = useState(0);
  
    const [payingAmount, setPayingAmount] = useState(0);
    const requiresCustomerRef = useRef(null);
    const accessToken = localStorage.getItem('accessToken');
    const [searchValue, setSearchValue] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);

    const [deliveryDate, setDeliveryDate] = useState('');
    const [details, setDetails] = useState('');
    const [transactionRef, setTransactionRef] = useState('');

     const handleDateChange = (e) => {
        setDeliveryDate(e.target.value);
      };
      const handleDetailsChange = (e) => {
        setDetails(e.target.value);
      };

      const handleTransactionChange = (e) => {
        setTransactionRef(e.target.value);
      };

    const [customerInfo, setCustomerInfo] = useState(null); 
    const [bankInfo, setBankInfo] = useState(null); 

    const handleCustomerChange = (selectedCustomer) => {
        setCustomerInfo(selectedCustomer); 
        if(selectedCustomer){
            getRequiresCustomerList(selectedCustomer.value);
        }else{
            setTableRow([]);
        }
    };

    const handleBankChange = (selectedBank) => {
        setBankInfo(selectedBank); 
        console.log("Selected bank:", selectedBank);
       
    };

    

    const [tableRow, setTableRow] = useState([]);
    

    const getRequiresCustomerList = async (customerId) => {
        setTableRow([]);
        const formData = new FormData();
        formData.append('customer_id', customerId);

        const apiUrl = `${config.api_url}/ws-received-customer-payment`;

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });

          if (!response.ok) 
            {
              throw new Error('Failed to fetch new sales data');
            }

          const data = await response.json();

          const rows = data.data ? data.data : [];
            setTableRow(rows);

           
        } catch (error) {
          console.error('Error fetching new sales data:', error);
        }
        

    };

    useEffect(() => {
       
    }, [searchValue]);

    
  

    const handlePayChange = (index, value) => {
        const newTableRow = [...tableRow];
        newTableRow[index].pay = parseFloat(value) || 0;
        setTableRow(newTableRow);

        calculateTotals(newTableRow);
    };

    const handleCheckboxChange = (index) => {
        setCheckedRows((prevCheckedRows) => {
            const newCheckedRows = prevCheckedRows.includes(index)
                ? prevCheckedRows.filter((rowIndex) => rowIndex !== index) 
                : [...prevCheckedRows, index]; 

            calculateTotals(tableRow, newCheckedRows);
            return newCheckedRows;
        });
    };

    const calculateTotals = (rows, selectedRows = checkedRows) => {
        const totalPay = rows
            .filter((_, idx) => selectedRows.includes(idx))
            .reduce((total, row) => total + (row.pay || 0), 0);
        setPayingAmount(totalPay);

        const totalBill = rows
            .filter((_, idx) => selectedRows.includes(idx))
            .reduce((sum, row) => sum + (parseFloat(row.value) || 0), 0);
        setBills(totalBill);
    };

    const handleSubmit = async (event) =>
     {
        event.preventDefault();

        const formData = new FormData();
        const customer = customerInfo ? customerInfo.value : '';
        const dDate = deliveryDate ? deliveryDate : '';
        const bank = bankInfo ? bankInfo.value : '';
        const detailInfo = details ? details : '';
        const transactionInfo = transactionRef ? transactionRef : '';

        formData.append('customer_id', customer);
        formData.append('date', dDate);
        formData.append('bank_id', bank);
        formData.append('details', detailInfo);
        formData.append('transaction_ref', transactionInfo);
        formData.append('paying_amount', payingAmount);
        formData.append('balance', payingAmount - bills);

        // Gather data for the checked rows
        const selectedRowsData = tableRow
            .filter((_, idx) => checkedRows.includes(idx))
            .map((row) => ({
                sales_id: row.sales_id,
                pay: row.pay || 0,
            }));

        formData.append('selected_rows[]', JSON.stringify(selectedRowsData));

        // Send the form data to the backend
        const apiUrl = `${config.api_url}/ws-submit-received-payment`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to submit payment data');
            }

            const data = await response.json();
            console.log('Submission successful:', data);

            // Reset the form if submission is successful (optional)
            /*setCheckedRows([]);
            setPayingAmount(0);
            setBills(0);
            setTableRow([]);*/
        } catch (error) {
            console.error('Error submitting payment data:', error);
        }
    };
    

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Receive Payment</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order' >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left' , width: '100%' }}>
                                        <div className="newsalesorderform">
                                            <div style={{ float: "right", width: "100%" }}>
                                                <a href="#" className="create-catalogue-btn"><i className='fa fa-plus'></i>&nbsp;New Customer</a>
                                            </div>
                                            <hr />
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="customer" className="col-form-label">Select Customer* </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <CustomerSuggestionBox onCustomerChange={handleCustomerChange} />


                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="delivery_date" className="col-form-label">Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" onChange={handleDateChange}  value={deliveryDate}/>
                                                        </div>
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bank" className="col-form-label">Bank </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <BankSuggestionBox onBankChange={handleBankChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="details" className="col-form-label">Details </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name="details"  onChange={handleDetailsChange} value={details} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="transaction_ref" className="col-form-label">Transaction Ref </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name="transaction_ref"  onChange={handleTransactionChange} value={transactionRef} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="paying_amount" className="col-form-label">Paying Amount</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" disabled value={payingAmount} name="paying_amount" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="balance" className="col-form-label">Balance</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" disabled value={payingAmount - bills} name="balance" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="verical-scrl-wrapper common-table receivepayment-table" id="style-3">
                                            
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>Job Ref</th>
                                                        <th>S.O. No</th>
                                                        <th>Raised On</th>
                                                        <th>Type</th>
                                                        <th>Customer (ID)</th>
                                                        <th>Value</th>
                                                        <th>Balance</th>
                                                        <th>Pay</th>
                                                        <th>Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableRow.length > 0 ? (
                                                        tableRow.map((row, index) => (
                                                            <tr key={index}>

                                                                <td>
                                                                    <label className="checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={() => handleCheckboxChange(index)}
                                                                        />
                                                                        <span className="default"></span>
                                                                    </label>
                                                                </td>
                                                                <td>{row.job_ref}</td>
                                                                <td>{row.sales_id}</td>
                                                                <td>{row.date}</td>
                                                                <td>{row.type}</td>
                                                                <td>
                                                                    <a
                                                                        className="viewbtn"
                                                                        href="#"
                                                                        data-id={row.customer_id}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#view_customer"
                                                                        title="View Customer"
                                                                    >
                                                                        {row.company_name} ({row.customer_id})
                                                                    </a>
                                                                </td>
                                                                <td>{row.value}</td>
                                                                <td>{row.balance}</td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        name="pay[]"
                                                                        className="form-control qtyinputbox no-arrows"
                                                                        value={row.pay || ''}
                                                                        style={{ textAlign: 'right' }}
                                                                        onChange={(e) => handlePayChange(index, e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>{row.due_date}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                No data available.
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>
                                        <hr />
                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button onClick={handleSubmit} type="button" className="saveclose">Save & Close</button>
                                            <button onClick={handleSubmit} type="button" className="savenew">Save & New</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default ReceivePayment;
