import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';

const JournalEntry = () => {
    const [customer, setCustomer] = useState([]); // Initialize as an array
    const [bills, setBills] = useState([]);
    const [selectedCustomer, Customer] = useState('');
    const [payingAmount, setPayingAmount] = useState('');


    

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Journal Entry</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left' , width: '100%' }}>
                                        <div className="newsalesorderform">
                                            
                                            <div className="row padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                   
                                                    <div className="row">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="delivery_date" className="col-form-label">Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" />
                                                        </div>
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bank" className="col-form-label">Journal No. </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                        <input type="text" name="qty[]" class="form-control qtyinputbox" autocomplete="off" value="763" />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        <div className="verical-scrl-wrapper common-table receivepayment-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Account</th>
                                                        <th>Debit</th>
                                                        <th>Credit</th>
                                                        <th>Description</th>
                                                        <th>Vat</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   
                                                        <tr>
                                                            <td>01</td>
                                                            <td>0020 Plant and Machinery</td>
                                                            <td> <input type="text" name="qty[]" class="form-control qtyinputbox" autocomplete="off" value="15000" /></td>
                                                            <td>&nbsp;</td>
                                                            <td> <input type="text" name="qty[]" class="form-control qtyinputbox" autocomplete="off" value="Purchase of Truck" /></td>
                                                            <td>
                                                                <div class="btn-group">
                                                                    <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                                                                    <ul class="dropdown-menu dropdown-menu-arrow">
                                                                        <li>0 %</li>
                                                                        <li>20 %</li>
                                                                    </ul>
                                                                    </div>
                                                              </td>
                                                           <td>
                                                           <a href="#"><i class="fa fa-trash"></i></a>
                                                           </td>
                                                         </tr>


                                                         <tr>
                                                            <td>02</td>
                                                            <td>0020 Plant and Machinery</td>
                                                            <td>&nbsp;</td>
                                                            <td> <input type="text" name="qty[]" class="form-control qtyinputbox" autocomplete="off" value="15000" /></td>
                                                            
                                                            <td> <input type="text" name="qty[]" class="form-control qtyinputbox" autocomplete="off" value="Purchase of Truck" /></td>
                                                            <td><div class="btn-group">
                                                                    <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                                                                    <ul class="dropdown-menu dropdown-menu-arrow">
                                                                        <li>0 %</li>
                                                                        <li>20 %</li>
                                                                    </ul>
                                                                    </div></td>
                                                           <td>
                                                           <a href="#"><i class="fa fa-trash"></i></a>
                                                           </td>
                                                         </tr>
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button type="button" className="saveclose">Save & Close</button>
                                            <button type="button" className="savenew">Save & New</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default JournalEntry;
