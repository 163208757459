import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import generatePDFNew from './PurchaseHistoryInvoice';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const PurchaseHistoryView = () => {
    const { id } = useParams();
    const accessToken = localStorage.getItem('accessToken');
    const [productList, setProductList] = useState([]);
    const [supplierAddress, setSupplierAddress] = useState([]);

     let subtotal = 0;
     let totalvat=  0;
     let total=0;
     let gross_weight =0;
     let  net_weight =0;
     let  cbm =0;

    const fetchData = async () => {
        const formData = new FormData();
        formData.append('id', id);

        const apiUrl = `${config.api_url}/ws-get-purchase-history`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch purchase data');
            }

            const data = await response.json();

            console.log(data);

            setProductList(data.products);
            setSupplierAddress(data.headerSection2);

        } catch (error) {
            console.error('Error fetching purchase data:', error);
        }
    };

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
        }
        fetchData();
    }, []);

  const calculateAmount = (quantity, price) => {
    const qty = Number(quantity);
    const prc = Number(price);
    return isNaN(qty) || isNaN(prc) ? 0 : qty * prc;
};
    const calculateVAT = (amount, rate) => {
    const amt = Number(amount);
    const r = Number(rate);
    return isNaN(amt) || isNaN(r) ? 0 : (amt * r) / 100;
};

   const calculateTotalPrice = (quantity, price, rate) => {
    const amt = calculateAmount(quantity, price); // Ensure this returns a number
    const vat = calculateVAT(amt, rate); // Ensure this returns a number
    return isNaN(amt) || isNaN(vat) ? 0 : amt + vat; // Return 0 if either is NaN
};



    
    const handleGeneratePurchaseInvoicePDF = (id) => {
        console.log('Generating PDF for ID:', id); // Log the ID for debugging
        //generatePDF(id);
        generatePDFNew(id);
      };



    return (
        <div>
        <Header />
        <div className="breadcrumbs">
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Purchase Order </span>
        </div>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col-12">
        <div className="show-order-area dashboard">
        <div className="sales-view-area" style={{ width: '100%', border: 'none' }}>
        <div className="row">
            <div className="col-7">
                
                <button onClick={(e) => handleGeneratePurchaseInvoicePDF(id)}>
                  Generate PDF
                </button>
            </div>
        </div>
        <div className="row">
        <div className="col-7">
      
        </div>
        </div>

        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">
        <h5>Star Pacific UK LTD</h5>
        <p>Unit 6 Abenglen Industrial Estate<br />Middlesex, London UB3 1AT<br />United Kingdom</p>
        </div>
        <div className="col-3">
        <ul>
        <li><b>Co. Reg. No :</b> 7298396</li>
        <li><span>VAT Reg. No.:</span> GB994533184</li>
        <li><span>Tel :</span> 44-208 329 3800</li>
        </ul>
        </div>
        </div>
        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">
        <ul>
        <li><b>Supplier Address : </b>  {supplierAddress.address_line1}</li>
         <li><span> {supplierAddress.address_line2}</span></li>
        <li><span> {supplierAddress.city}</span></li>
        <li><span>{supplierAddress.country}{supplierAddress.postcode}</span></li>

        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><b>VAT Reg. No :</b> {supplierAddress.vat_id}</li>
        <li><span>Rep : </span> {supplierAddress.contact_person_name }</li>
        <li><span>Account No :</span> {supplierAddress.bank_account_number}</li>
        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><span>PO No :</span>  {supplierAddress.reference_no}</li>
        <li><span>Date : </span>  06.07.2021</li>
        <li><span>Jon Ref :</span> {supplierAddress.job_ref}</li>
        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><span>Currency :</span> {supplierAddress.currency_id}</li>
        <li><span>Date of Arrival : </span> {supplierAddress.arrival_date}</li>

        <li><span>SO# :</span> </li>
        </ul>
        </div>
        </div>
        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">

    {supplierAddress.delivery_type === 'collection' && (


          <ul>
        <li><b>Collection Address : </b> {supplierAddress.collection_address_line1}</li>
        <li><span>{supplierAddress.collection_address_line2}</span></li>    
        <li><span>{supplierAddress.collection_city}</span></li>
        <li><span>{supplierAddress.collection_state}</span></li>
        <li><span>{supplierAddress.collection_country} {supplierAddress.collection_postcode}</span></li>
        </ul>      

        )}



    { (supplierAddress.is_wharehouse_or_customer === 'warehouse' && supplierAddress.delivery_type === 'delivery') && (

        <ul>
        <li><b>Delivery Address : </b> {supplierAddress.name}</li>
        <li><span>{supplierAddress.address_line1}</span></li>
        <li><span>{supplierAddress.address_line2}</span></li>
        <li><span>{supplierAddress.city}</span></li>
        <li><span>{supplierAddress.state}</span></li>
        <li><span>{supplierAddress.country} {supplierAddress.postcode}</span></li>
        </ul>
        )}

{ (supplierAddress.is_wharehouse_or_customer === 'customer' && supplierAddress.delivery_type === 'delivery') && (

        <ul>
        <li><b>Customer Address : </b> {supplierAddress.name}</li>
        <li><span>{supplierAddress.customer_delivery_address_line1}</span></li>
        <li><span>{supplierAddress.customer_delivery_address_line2}</span></li>
        <li><span>{supplierAddress.customer_delivery_city}</span></li>
        <li><span>{supplierAddress.customer_delivery_state}</span></li>
        <li><span>{supplierAddress.customer_delivery_country} {supplierAddress.customer_delivery_postcode}</span></li>
        </ul>
        )}




        </div>
        <div className="col-3">                                    
        </div>


        </div>
        <div className="sales-view-table">
       <table className="table table-bordered" id="purchaseProforma">
    <thead>
        <tr>
            <th style={{ backgroundColor: '#eee9e9' }}>Line No</th>
            <th style={{ backgroundColor: '#eee9e9' }}>SKU Code</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Product Description</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Pack</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Size</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Unit Barcode</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Case Qty</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Unit Price</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Amount</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Vat Rate (%)</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Vat Amount</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Total Amount</th>
        </tr>
    </thead>
   <tbody>
    {productList.map((product, index) => {
        const amount = calculateAmount(product.quantity, product.cost);
        const vat = calculateVAT(amount, product.rate);
        const totalPrice = calculateTotalPrice(product.quantity, product.cost, product.rate);
        
        // Update subtotal
        subtotal += amount; 
        totalvat += vat;
        total=subtotal + totalvat;

         gross_weight = product.gross_weight;
         net_weight =  product.net_weight;
            cbm =  product.cbm;
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{product.code}</td>
                <td>{product.product_desc}</td>
                <td>{product.pack}</td>
                <td>{product.size}</td>
                <td>{product.unit_barcode}</td>
                <td>{product.quantity}</td>
                <td>{product.cost}</td>
                <td><span>{amount.toFixed(2)}</span></td>
                <td>{product.rate}</td>
                <td><span>{vat.toFixed(2)}</span></td>
                <td><span>{totalPrice.toFixed(2)}</span></td>
            </tr>
        );
    })}
</tbody>
<tfoot>
    <tr>
        <td colSpan={8} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>

        <td><span>{subtotal.toFixed(2)}</span></td>
        <td></td>

        <td><span>{totalvat.toFixed(2)}</span></td>
        <td><span>{total.toFixed(2)}</span></td>
    </tr>
</tfoot>

</table>

        </div>

        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-7">
        <ul>
        <li><span>Notes : </span>  Delivered on 15/08; N5267</li>
        <li><span>Ref : </span> VBG 5KG; Transportify</li>
        <li></li>

        </ul>
        </div>
        <div className="col-2">

        </div>
        <div className="col-3">
        <ul>
        <li><span>Gross Weight: {gross_weight} KGS</span></li>
        <li><span>CBM: {cbm} M3</span></li>
        <li><span>Net weight:{net_weight} KGS</span></li>
        </ul>
        </div>

        </div>
        </div>
        </div>
        </div>
        </div>
         <footer className="text-center mt-4">
        <p className="lh-base">
          <strong>Subject to Star Pacific Limited terms and conditions of sale, a copy of which is available on request.</strong><br />
        </p>
        <hr />
        <p>
          Reservation of Title. The property in and the title to all goods sold by Star Pacific UK LTD shall not pass to the buyer until all monies due and owing have been paid in full
        </p>
      </footer>
        </div>
        );
    };

    export default PurchaseHistoryView;
