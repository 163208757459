import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";

const ViewProduct = () => {
  return (
    <div>
      <div className="modal fade" id="viewproduct" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="viewcustomer-area">
                <form id="viewProductForm">
                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">&nbsp;</div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row">
                        <label htmlFor="inputText" className="col-sm-4" >&nbsp;</label>
                        <div className="col-sm-8">
                          <h4 id="product_status"></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="devider" />
                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Code</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="code" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Brand</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="brand" id="brandname" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Pack</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="packValue" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">VAT</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="vat" id="vat" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Gross Weight</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="weight" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Net Weight</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="supplier" id="editnetWeight" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">CBM</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="cbm" id="viewcbm" disabled />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Supplier</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="supplier" id="supplier" disabled />
                        </div>
                      </div>

                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Description</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="description" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Category</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="categooryName" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Size</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="sizeValue" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Unit Barcode</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="barcode" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Case Barcode</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="view_case_barcode" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Case Price</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="case_price" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Sell Price</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="editgrossWeight" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Pallet Qty</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" id="pallet_qty" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="viewcustomer-tbl-area">
                  <h5>Stock</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Free</th>
                        <th>Stock</th>
                        <th>On S.O</th>
                        <th>Alloc</th>
                        <th>On PF</th>
                        <th>On PO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Qty</td>
                        <td id="freestock">
                        </td>
                        <td id="stock"></td>
                        <td id="on_so"></td>
                        <td id="allocation"></td>
                        <td id="onpf"></td>
                        <td id="onpo"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="viewcustomer-tbl-area">
                  <h5>Last 12 Month Turnover</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>Jun</th>
                        <th>Jul</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>T/O</td>
                        <td>13010</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>3244</td>
                        <td>0</td>
                        <td>2343</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
