import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import Swal from "sweetalert2";
const accessToken = localStorage.getItem('accessToken');

    export const fetchCustomerData = async (setCustomerData) => {
        const apiUrl = `${config.api_url}/ws-get-customer-list`;
        try {
          const response = await axios.post(apiUrl, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });
      
          if (Array.isArray(response.data.customer)) 
            {
            const formattedOptions = response.data.customer.map(item => ({
              label: `${item.company_name}`,
              value: item.id,
            }));

            setCustomerData(formattedOptions);
          }   
        } catch (error) {
          console.error("Error fetching products data:", error);
        }
      };

      export const fetchEditCustomerData = async (setCustomerData, selectedValue) => {
        const apiUrl = `${config.api_url}/ws-get-customer-list`;
        try {
          const response = await axios.post(apiUrl, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });

          if (Array.isArray(response.data.customer)) {
            const formattedOptions = response.data.customer.map(item => ({
              label: `${item.company_name}`,
              value: item.id,
            }));

            // Set the customer data
            setCustomerData(formattedOptions);

            // Find the selected customer option
            const selectedOption = formattedOptions.find(option => option.value === selectedValue);
            return selectedOption || null; // Return the selected option or null
          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
        }
      };



      export const fetchWarehouseData = async (setWarehouseData) => {
        const apiUrl = `${config.api_url}/ws-get-warehouse`;
        try {
          const response = await axios.post(apiUrl, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });
      
          if (Array.isArray(response.data.warehouse)) {
            const formattedOptions = response.data.warehouse.map(item => ({
              label: `${item.name}`,
              value: item.id,
            }));
 
            setWarehouseData(formattedOptions);
          }   

        } catch (error) {
          console.error("Error fetching warehouse data:", error);
        }
      };
 
   /* export const handleCustomerChange = async (e, setInvoiceAddress,setDeliveryAddrDropdown, setDeliveryAddresses, setInputDeliveryAddresses) => {*/

    export const handleCustomerChange = async (e, setInvoiceAddress,setDeliveryAddrDropdown, setDeliveryAddresses, setInputDeliveryAddresses = () => {}) => {
        let id = e?.value;
        if(!id)
        {
          return  0;
        }

        const formData = new FormData();
        formData.set('customer_id', id);
        const response = await axios.post(`${config.api_url}/ws-get-customer-list-by-id`, formData, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
              }
        });

        //console.log(response.data);

        setDeliveryAddrDropdown(response.data.delivery_addresses);
        setDeliveryAddresses(response.data.delivery_addresses);
        setInvoiceAddress(response.data.customer);
        
          // Call setInputDeliveryAddresses only if it is provided
        if (typeof setInputDeliveryAddresses === 'function') {
            setInputDeliveryAddresses({
                'address_line1': '',
                'address_line2': '',
                'city': '',
                'country': '',
                'post_code': ''
            });
        }
      

        
    };

    export const handleProductAdd = async (e, tableRows, setTableRows) => {
      const id = e?.value;
      if (id == null) return;
    
      const found = tableRows?.find(element => element.id === id);
      if (found) {
        Swal.fire({
          title: "Error!",
          text: "Product already exists",
          icon: "error",
          timer: 1500, 
          showConfirmButton: false,
        })
          return;
        }

      const formData = new FormData();
      formData.append("id", id);
      const apiUrl = `${config.api_url}/ws-get-products-by-id`;
      try {
            const response = await axios.post(apiUrl,formData, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            }
          });
  
            const data = await response.data;

            

            const newRow = {
            id: data.products[0].id,
            code: data.products[0].code,
            product_desc: data.products[0].product_desc,
            pack: data.products[0].pack,
            size: data.products[0].size,
            price: data.products[0].price,
            cost: data.products[0].cost,
            quantity: data.products[0].quantity,
            taxrate: data.products[0].rate,
            grossWeight: data.products[0].gross_weight,
            netWeight: data.products[0].net_weight,
            cbm: data.products[0].cbm,
            vat: parseFloat(data.products[0].vat),
            value: 0,
            profit: (data.products[0].price-data.products[0].cost),
            batch_id: 0
          };
          
          // Update table rows
          setTableRows(prevRows => [...prevRows, newRow]);
        } catch (error) {
          console.error("Error fetching products data:", error);
        }
      };

      export const handleProductAddNew = async (e, tableRows, setTableRows) => {
        const id = e?.value;
        if (id == null) return;
    
        const found = tableRows?.find(element => element.id === id);
        if (found) {
          Swal.fire({
            title: "Error!",
            text: "Product already exists",
            icon: "error",
            timer: 1500, 
            showConfirmButton: false,
        })
          return;
        }

        const formData = new FormData();
        formData.append("id", id);
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await axios.post(apiUrl,formData, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            }
          });
  
          const data = await response.data;

          console.log(data);
            

          const newRow = {
            id: data.products[0].id,
            code: data.products[0].code,
            product_desc: data.products[0].product_desc,
            pack: data.products[0].pack,
            size: data.products[0].size,
            price: data.products[0].price,
            cost: data.products[0].cost,
            quantity: data.products[0].quantity,
            taxrate: data.products[0].rate,
            grossWeight: data.products[0].gross_weight,
            netWeight: data.products[0].net_weight,
            cbm: data.products[0].cbm,
            vat: parseFloat(data.products[0].vat),
            value: 0,
            profit: (data.products[0].price-data.products[0].cost),
            batch_id: 0,
            pdtqty : 0,
            purchase_id : 0,
            //po : data.products[0].po,
            //mappedPO : data.products[0].mappedPO,

            receivedQty : data.products[0].receivedQty ? data.products[0].receivedQty : 0 ,
            mappedPO : data.products[0].mappedPO ? data.products[0].mappedPO : 0,
            Onpo : data.products[0].Onpo ? data.products[0].Onpo : 0,
          };
          
          // Update table rows
          setTableRows(prevRows => [...prevRows, newRow]);
        } catch (error) { 
          console.error("Error fetching products data:", error);
        }
      };

      export const handleSalesProductAddNew = async (e, tableRows, setTableRows) => {
        const id = e?.value;
        if (id == null) return;
    
        const found = tableRows?.find(element => element.id === id);
        if (found) {
          Swal.fire({
            title: "Error!",
            text: "Product already exists",
            icon: "error",
            timer: 1500, 
            showConfirmButton: false,
        })
          return;
        }

        const formData = new FormData();
        formData.append("id", id);
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await axios.post(apiUrl,formData, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            }
          });
  
          const data = await response.data;

          console.log(data);
            

          const newRow = {
            id: data.products[0].id,
            code: data.products[0].code,
            product_desc: data.products[0].product_desc,
            pack: data.products[0].pack,
            size: data.products[0].size,
            price: data.products[0].price,
            cost: data.products[0].cost,
            quantity: data.products[0].quantity,
            taxrate: data.products[0].rate,
            grossWeight: data.products[0].gross_weight,
            netWeight: data.products[0].net_weight,
            cbm: data.products[0].cbm,
            vat: parseFloat(data.products[0].vat),
            value: 0,
            profit: (data.products[0].price-data.products[0].cost),
            batch_id: 0,
            pdtqty : 0,
            purchase_id : 0,
            quantity : data.products[0].quantity,
            //po : data.products[0].po,
            //mappedPO : data.products[0].mappedPO,

            receivedQty : data.products[0].receivedQty ? data.products[0].receivedQty : 0 ,
            mappedPO : '',
            Onpo : data.OnPO.totalPO ? data.OnPO.totalPO : 0,
            fstock : (data.OnPO.totalPO + data.products[0].quantity),
            allocation : data.OnPO.allocation ? data.OnPO.allocation : 0,
            freestock : (data.freestock !== undefined && data.freestock !== null) ? parseInt(data.freestock) + parseInt(data.products[0].quantity) : 0,
            freestockcal:(data.freestock !== undefined && data.freestock !== null) ? parseInt(data.freestock) + parseInt(data.products[0].quantity) : 0,    
            onpurchase : data.OnPO.totalPO ? data.OnPO.totalPO : 0,
          };
          
          // Update table rows
          setTableRows(prevRows => [...prevRows, newRow]);
        } catch (error) {
          console.error("Error fetching products data:", error);
        }
      };

      export const getDeliveryAddrById = (e, deliveryAddresses, setInputDeliveryAddresses) => {
            //let id = e.target.value;
            let id;
            if (e.target) {
              id = e.target.value; // From event
            } else {
              id = e.value; // Direct ID from API response
            }

            if(id == 'Select'){
              setInputDeliveryAddresses({
                'address_line1' : '',
                'address_line2' : '',
                'city' : '',
                'country' : '',
                'post_code' : ''
              });
              
            }else{
              
              let data = deliveryAddresses.find(item => item.id == id);
         
              setInputDeliveryAddresses({
                'address_line1' : data.address_line1,
                'address_line2' : data.address_line2,
                'city' : data.city,
                'country' : data.country,
                'post_code' : data.post_code
              });
            }
           
      }


      export const handleBatchChange = (event, setSelectedBatchId) => {
        setSelectedBatchId(event.target.value);
      };

      export const handleSalesBatchChange = (event, setSelectedBatchId, setSelectedpurchaseId) => 
      {


      
        setSelectedBatchId(event.target.value);
        setSelectedpurchaseId(event.target.dataset.purchaseid);
  
      };

      /*export const handleSalesBatchChangeEdit = (event, setSelectedBatchId, setSelectedpurchaseId) => {
           const { value, dataset, checked } = event.target; // Destructure event target properties

            // Convert value to a number if necessary
            const batchId = Number(value);
            const purchaseId = dataset.purchaseid; // Get the purchase ID

            // Update the selectedBatchId based on whether the checkbox is checked or unchecked
            setSelectedBatchId(prev => {
                // If checked, add the batchId to the array; if unchecked, remove it
                if (checked) {
                    return [...prev, batchId]; // Add to the array
                } else {
                    return prev.filter(id => id !== batchId); // Remove from the array
                }
            });

        // Update selectedPurchaseId (if necessary)
        setSelectedpurchaseId(prev => {
            if (checked) {
                // If checked, add the purchaseId if not already present
                return [...prev, purchaseId];
            } else {
                // If unchecked, remove the purchaseId
                return prev.filter(id => id !== purchaseId);
            }
        });
      };*/

      export const handleSalesBatchChangeEdit = (event, setSelectedBatchId, setSelectedpurchaseId) => {
    const { value, dataset, checked } = event.target;

        // Convert value to a number if necessary
        const batchId = Number(value);
        const purchaseId = dataset.purchaseid; // Get the purchase ID

        // Update the selectedBatchId based on whether the checkbox is checked or unchecked
        setSelectedBatchId(prev => {
            if (checked) {
                return [...prev, batchId]; // Add to the array
            } else {
                return prev.filter(id => id !== batchId); // Remove from the array
            }
        });

        // Update selectedPurchaseId based on the checkbox state
        setSelectedpurchaseId(prev => {
            if (checked) {
                // If checked, add the purchaseId if not already present
                return [...prev, purchaseId];
            } else {
                // If unchecked, remove the purchaseId
                return prev.filter(id => id !== purchaseId);
            }
        });
};





      export const handleSalesBatchChangeNew = (event, setSelectedBatchId, setSelectedpurchaseId, setSelectedCost) => 
      {

        setSelectedBatchId(event.target.value);
        setSelectedpurchaseId(event.target.dataset.purchaseid);
        setSelectedCost(event.target.dataset.cost);
      };
      
      export const setBatchToProduct = (product_id, selectedBatchId, setTableRows) => {

       console.log(product_id + '---'+selectedBatchId);

        setTableRows((prevRows) => 
          prevRows.map(item => 
            item.id === product_id ? 
            { 
              ...item, 
              batch_id: selectedBatchId 
            } 
            : item
          )
        );
      };
      

      export const setSalesBatchToProduct = (product_id, selectedBatchId, selectedpurchaseId, setTableRows) => {

  

        setTableRows((prevRows) => 
          prevRows.map(item => 
            item.id === product_id ? 
            { 
              ...item, 
              batch_id: selectedBatchId,
              purchase_id : selectedpurchaseId,
            } 
            : item
          )
        );
      };

      export const setSalesBatchToProductNew = (product_id, selectedBatchId, selectedpurchaseId, setTableRows, selectedCost) => {

  

        setTableRows((prevRows) => 
          prevRows.map(item => 
            item.id === product_id ? 
            { 
              ...item, 
              batch_id: selectedBatchId,
              purchase_id : selectedpurchaseId,
            //  cost : selectedCost,
            } 
            : item
          )
        );
      };

    // Modify this function to use FormData
    // export  const submitSalesOrderForm = async (tableRows) => {
    //   const form = document.getElementById('new_sales_order');
    //   const formData = new FormData(form);
      
    //   // Manually append the table rows data
    //   tableRows.forEach((row, index) => {
    //     formData.append(`code[${index}]`, row.code);
    //     formData.append(`expiryDate[${index}]`, row.expiryDate);
    //     formData.append(`packsize[${index}]`, row.pack);
    //     formData.append(`productSize[${index}]`, row.size);
    //     formData.append(`qty[${index}]`, row.quantity);
    //     formData.append(`price[${index}]`, row.price);
    //     formData.append(`value[${index}]`, row.value);
    //     formData.append(`Disc[${index}]`, row.disc);
    //     formData.append(`NetP[${index}]`, row.netP);
    //     formData.append(`Vat[${index}]`, row.vat);
    //     formData.append(`currrentStock[${index}]`, row.curStock);
    //     formData.append(`onSO[${index}]`, row.onSO);
    //     formData.append(`fstk[${index}]`, row.fstk);
    //   });

    //   try {
    //     const response = await axios.post(`${config.api_url}/ws-sales-add`, formData, {
    //       headers: {
    //         authorization: `Bearer ${accessToken}`,
    //       }
    //     });

    //     const result = response.data;
    //     Swal.fire({
    //       title: "Success!",
    //       text: "Order submitted successfully.",
    //       icon: "success",
    //       timer: 1500, // Automatically close after 1500ms
    //       showConfirmButton: false,
    //     })

    //     // navigateToGRNList('/GRN');
      
    //   } catch (error) {
    //     console.error("Error accepting order:", error);
    //   }
    // };
    
    
