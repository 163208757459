import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';


const Accounting_Code_Master = () => {
  const accessToken = localStorage.getItem('accessToken');
  const modalRef = useRef(null);
  const editModalRef = useRef(null);
  const tableRef = useRef(null); // Reference to the USD table element
  const tableRefEUR = useRef(null); // Reference to the EUR table element
  const tableRefGBP = useRef(null); // Reference to the GBP table element
  const [selectedTab, setSelectedTab] = useState('EUR');


  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Accounting Code Master</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className='bank-pagearea'>
                <div class="">
                  <div>
                    <div>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#eur" onClick = {()=>setSelectedTab('EUR')}  type="button" role="tab" aria-controls="eur" aria-selected="true"> Accounting Code </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gbp" onClick = {()=>setSelectedTab('GBP')}  type="button" role="tab" aria-controls="gbp" aria-selected="false"> Category </button>
                        </li>
                        
                      </ul>
                      <div class="tab-content fulltable-area" id="myTabContent">
                        <div class="tab-pane fade show active" id="eur" role="tabpanel" aria-labelledby="eur-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right", marginBottom: "15px" }}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_accounting_code">Add Accounting Code</a>
                                </div>
                              </div>
                            </div>
                            <div className="verical-scrl-wrapper" id="style-3">
                              <table className="table table-bordered dataTable" ref={tableRefEUR}>
                                <thead>
                                  <tr>
                                    <th>Code </th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1200 </td>
                                    <td>HSBC Bank</td>
                                    <td>Cash & Bank</td>
                                    <td><label className="switch">
                                            <input className="switch-input" type="checkbox" name="status" id="status" />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                                        </label>
</td>
                                    <td><a href='#'>
                                        <i class="fa fa-edit"></i>
                                      </a></td>
                                    
                                  </tr>

                                  <tr>
                                    <td>1200 </td>
                                    <td>HSBC Bank</td>
                                    <td>Cash & Bank</td>
                                    <td><label className="switch">
                                            <input className="switch-input" type="checkbox" name="status" id="status" />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                                        </label>
</td>
                                    <td><a href='#'>
                                        <i class="fa fa-edit"></i>
                                      </a></td>
                                    
                                  </tr>

                                  <tr>
                                    <td>1200 </td>
                                    <td>HSBC Bank</td>
                                    <td>Cash & Bank</td>
                                    <td><label className="switch">
                                            <input className="switch-input" type="checkbox" name="status" id="status" />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                                        </label>
</td>
                                    <td><a href='#'>
                                        <i class="fa fa-edit"></i>
                                      </a></td>
                                    
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="gbp" role="tabpanel" aria-labelledby="gbp-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                               
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right", marginBottom: "15px"}}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_accounting_code_Category">Add Accounting Code Category</a>
                                </div>
                              </div>
                            </div>

                            <div className="verical-scrl-wrapper" id="style-3">
                              <table className="table table-bordered dataTable" ref={tableRefGBP}>
                                <thead>
                                  <tr>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>

                                <tr>
                                    <td>Cash & Bank</td>
                                    <td>Name</td>
                                    <td>Category</td>
                                    <td>Status</td>
                                    <td>Edit</td>
                                    
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {/* Add New bank Modal */}
       <div className="modal fade" id="add_accounting_code" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Accounting Code</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body add-product-popup">
                            <Formik>
                                {() => (
                                    <Form id='addBankForm'>
                                        <input type="hidden" className="form-control" name="currency" id='selectedCurrency' value="EUR" />

                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Code *</label>
                                            <div className="col-sm-7">
                                                <Field type="text" className="form-control" name="bank_name" />
                                                <ErrorMessage name="bank_name" component="div" className="text-danger" />
                                            </div>
                                            
                                        </div>


                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Accounting Code Name *</label>
                                            <div className="col-sm-7">
                                                <Field type="text" className="form-control" name="bank_name" />
                                                <ErrorMessage name="bank_name" component="div" className="text-danger" />
                                            </div>
                                         </div>


                                         <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Select Accounting Code Category *</label>
                                            <div className="col-sm-7">
                                                <Field type="text" className="form-control" name="bank_name" />
                                                <ErrorMessage name="bank_name" component="div" className="text-danger" />
                                            </div>
                                         </div>

                                         <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Status</label>
                                            <div className="col-sm-7">
                                            <label className="switch">
                                            <input className="switch-input" type="checkbox" name="status" id="status" />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                                        </label>

                                            </div>
                                         </div>

                                        <hr />
                                        <div className="row padd-l-13px">
                                            <div className="col-sm-12">
                                                <button type="submit" className="popup-innerbtn-common right">Save</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="add_accounting_code_Category" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Accounting Code</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body add-product-popup">
                            <Formik  >
                                {() => (
                                    <Form id='addBankForm'>
                                        <input type="hidden" className="form-control" name="currency" id='selectedCurrency' value="EUR" />

                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Accounting Code Category Name *</label>
                                            <div className="col-sm-7">
                                                <Field type="text" className="form-control" name="bank_name" />
                                                <ErrorMessage name="bank_name" component="div" className="text-danger" />
                                            </div>
                                            
                                        </div>

                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-5 col-form-label">Status</label>
                                            <div className="col-sm-7">
                                            <label className="switch">
                                            <input className="switch-input" type="checkbox" name="status" id="status" />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span> <span className="switch-handle"></span>
                                        </label>

                                            </div>
                                         </div>
                                        

                                        <hr />
                                        <div className="row padd-l-13px">
                                            <div className="col-sm-12">
                                                <button type="submit" className="popup-innerbtn-common right">Save</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>   

   

      <Footer />
    </div>
  );
};

export default Accounting_Code_Master;
