import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!username || !password) {
            setError('Please enter both username and password');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const response = await fetch('https://payroll.peoplehrm.in/ws-login', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();
            // Handle successful login
            console.log('Login successful:', data);

            // Store token in localStorage or sessionStorage for future requests
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('name', data.profile_details.name);
            localStorage.setItem('user_id', data.profile_details.id);
            localStorage.setItem('role_id', data.profile_details.role_id);

            

            // Redirect to dashboard or perform any action upon successful login
             navigate('/dashboard');  // Replace with your dashboard route

            setError(null); // Clear any previous error
        } catch (error) {
            setError(error.message || 'Login failed');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-body d-flex flex-column h-100">
            <div className="login-container">
                <div className="login-content">
                    <div className="login-content_header">
                        <span className="logo">
                            <img src="/assets/img/logo1.jpg" alt="Ebusso Logo" />
                        </span>
                        <p>Please login to your account.</p>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
                            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                            {error && <p className="text-danger">{error}</p>}
                            <button type="submit" className="login-submit-btn">Submit</button>
                        </form>
                        <div className="login-networks">
                            <p>Forgot your password?<br />Don't worry! <a href="#">Click here</a> to reset</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
