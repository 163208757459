import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const accessToken = localStorage.getItem('accessToken');

export const fetchGRNProducts = async (purchase_id, setGRNItems, setPurchaseData, setBatchData,setStdGoods, setPreSupplierData) => 
{
  $('#purchase_id').val(purchase_id);
  const apiUrl = `${config.api_url}/ws-purchase-item-by-id`;
  let formData = new FormData();
  formData.append('purchase_id', purchase_id);

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    const purchase_item = response.data.purchase_item;
    const initializedGRNItems = purchase_item.map(item => ({
      ...item,
      expiryData: [{ expiryDate: "", batchQty: "" }],
      //  received_qty: 0,
        batchQty: 0,
        newlyAdded: 0
      }));

     //   console.log('hello');
        // console.log(initializedGRNItems);
        // console.log('HI');
        
        setGRNItems(initializedGRNItems);
        setBatchData(response.data.batch_info);
        const purchase = response.data.purchase;
        setPurchaseData(purchase);
       // setStdGoods(purchase.std_goods);
       setPreSupplierData(response.data.purchase.supplier_id);
       if(purchase.delivery_type==='collection')
       {

        $('#addr1').val(purchase.collection_address_line1);
        $('#addr2').val(purchase.collection_address_line1);
        $('#city').val(purchase.collection_city);
       // $('#state').val(purchase.state);
       $('#country').val(purchase.collection_country);
       $('#postcode').val(purchase.collection_postcode);
       // $('#warehouse_id').val(purchase.warhouse_id);

     }
     
     if (purchase.is_wharehouse_or_customer === 'warehouse' && purchase.delivery_type === 'delivery') {
      $('#warehouse_name').val(purchase.warehouse_name);
      $('#wcode').val(purchase.code);
      $('#addr1').val(purchase.address_line1);
      $('#addr2').val(purchase.address_line2);
      $('#city').val(purchase.city);
      $('#state').val(purchase.state);
      $('#country').val(purchase.country);
      $('#postcode').val(purchase.postcode);
    $('#warehouse_id').val(purchase.warehouse_id); // Corrected spelling
  }

  if (purchase.is_wharehouse_or_customer === 'customer' && purchase.delivery_type === 'delivery') {
    $('#addr1').val(purchase.customer_delivery_address_line1);
    $('#addr2').val(purchase.customer_delivery_address_line2);
    $('#city').val(purchase.customer_delivery_city);
    $('#state').val(purchase.customer_delivery_state);
    $('#country').val(purchase.customer_delivery_country);
    $('#postcode').val(purchase.customer_delivery_postcode);
    // $('#warehouse_id').val(purchase.warehouse_id); // This line is commented out as per your original code
  }


  $('#arrival_date').val(purchase.arrival_date);
  $('#job_ref').val(purchase.job_ref);
  $('#notes').val(purchase.notes);
  
} catch (error) {
  console.error("Error fetching warehouse data:", error);
  alert("An error occurred while fetching the data.");
}
};

export const handleChange = async (selectedOption, grnItems, setGRNItems) => {
  const id = selectedOption;
  const found = grnItems.find(element => element.product_id === id);
  if (found !== undefined) {
    Swal.fire({
      title: 'Error!',
      text: 'Product already exists.',
      icon: 'error',
      timer: 1500,
      showConfirmButton: false
    });   
    $('#productList').val('');
    return;
  }

  $('#productList').val('');
  const formData = new FormData();
  formData.append("id", id);
  const apiUrl = `${config.api_url}/ws-get-products-by-id`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    const data = await response.json();
    const newRow = {
      id: data.products[0].id,
      product_desc: data.products[0].product_desc,
      product_id: data.products[0].id,
      code: data.products[0].code,
      pack: data.products[0].pack,
      size: data.products[0].size,
      price: data.products[0].price,
      cost: data.products[0].cost,
      vat: data.products[0].vat,
      current_stock: data.products[0].price,
      vat_percentage : data.products[0].vat,
      order_qty: 0,
      received_qty: 0,
      grn:0,
      newlyAdded: 1,
      expiryData : [
      {
        expiryDate : "",
        batchQty: ""
      }
      ]
    };

    setGRNItems((prevRows) => [...prevRows, newRow]);
    console.log(newRow);
  } catch (error) {
    console.error("Error fetching products data:", error);
  }
};

export const handleConfirmGRN = async (e, status, confirmModal,navigateToPOHistory) => {
  e.preventDefault();

  try {
    const formElement = document.getElementById("confirm_grn");
    const formData = new FormData(formElement);
    formData.append('status', status);
    const supplierId = formData.get("supplier_id");
    if (supplierId) {
    } else {
      Swal.fire({
        title: "Error!",
        text: "Supplier is Required",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    

    const response = await axios.post(
      `${config.api_url}/ws-confirm-grn`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${accessToken}`
        },
      }
      );

    if (response.data.success) {
      if (confirmModal.current) {
        const bsModal = window.bootstrap.Modal.getInstance(confirmModal.current);
        if (bsModal) bsModal.hide();
      }
      navigateToPOHistory('/' + response.data.redirect_url);
    }
    
  } catch (error) {
    console.error("Request Error:", error);
    Swal.fire({
      title: "Error!",
      text: "An error occurred while confirming grn.",
      icon: "error",
        timer: 1500, // Automatically close after 1500ms
        showConfirmButton: false,
      });
  }
};

export const handleInputChange = (e, id, grn, field, setGRNItems, grnItems, newlyAdded , order_qty) => 
{
  const value = parseInt(e.target.value) + parseInt(grn);

  console.log(value);

  if(!newlyAdded)
  {
    let a = 0;
    const  initializedItems = grnItems.map((item) => {
      if (item.id !== id || value > order_qty) {
        return item; 
      }
      
      a = 1; 
      return {
        ...item,
        received_qty: value
      };
    });
    
    if (a === 0) 
    {
      Swal.fire({
        title: "Error!",
        text: "Please enter received quantity less than or equal to grn",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => { e.target.value = ''; });
    } else 
    {
      setGRNItems(initializedItems); 
    }
  }
};

export const calculateValue = (quantity, price) => {
  return quantity && price ? quantity * price : 0;
};

export const acceptNumber = (value) => {
  return value.replace(/[^0-9.]/g, "");

  
};