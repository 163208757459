import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import { DatePicker } from "rsuite";
import * as echarts from 'echarts';

const Operations_Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statisticsData, setStatisticsData] = useState(null);
    const accessToken = localStorage.getItem('accessToken');



    return (
        <div>
            <Header />

            <nav class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12">
                            <a href="/Dashboard"><i class="fa fa-home"></i> Dashboard</a>
                            <span class="last-crumb">Operations Dashboard</span> </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <div className="show-order-area dashboard">
                                <div className="dashform">





                                    <div className='sopobx'>
                                        <div className='row'>
                                            <div className="col-12 col-lg-3 col-sm-3">
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-12 col-sm-12'>
                                                                <h3 className='card-title'>Sales Order</h3>
                                                                <div className='count'>
                                                                    <p>Total S.O</p>
                                                                    <h4>35</h4>
                                                                </div>
                                                                <hr />
                                                                <div className='tblbx'>
                                                                <div className='verical-scrl-wrapper-small'>
                                                                <table className='table table-bordered dataTable'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Customer</th>
                                                                            <th>SOs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Customer 1</td>
                                                                            <td>10</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Customer 2</td>
                                                                            <td>15</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Customer 3</td>
                                                                            <td>20</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Customer 4</td>
                                                                            <td>25</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                            <div className="col-12 col-lg-3 col-sm-3">
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-12 col-sm-12'>
                                                                <h3 className='card-title'>Purchase Order</h3>
                                                                <div className='count'>
                                                                    <p>Total P.O</p>
                                                                    <h4>15</h4>
                                                                </div>
                                                                <hr />
                                                                <div className='tblbx'>
                                                                <div className='verical-scrl-wrapper-small'>
                                                                <table className='table table-bordered dataTable'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Supplier</th>
                                                                            <th>SOs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Supplier 1</td>
                                                                            <td>10</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Supplier 2</td>
                                                                            <td>5</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Supplier 3</td>
                                                                            <td>20</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Supplier 4</td>
                                                                            <td>25</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Booking</h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <p>Total Number of Jobs Booked</p>
                                                                    <hr />
                                                                    <span>145</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <p>Total Number of Jobs Booked</p>
                                                                <hr />
                                                                <span>36</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Operations_Dashboard;
