import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const CreditNote = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `https://apidev.ebusso.com/get_complete_trip_list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setCompleteTrips(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
  }
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#completeTripsTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: completeTrips,
      columns: [
        { data: null },
        { data: 'date' },
        {
          data: 'trip_number',
          render: function (data, type, row) {
            return '<a href="CloseTripSummary' + row.id + '">' + row.trip_number + '</a>';
          }
        },
        { data: 'driver' },
        { data: 'vehicle', className: "text-center" },
        // { data: 'status', className: "text-center" },
        //{ data: 'sale_status' },

        {
          data: null,
          render: function (data, type, row) {

            return '<span class="btn btn-primary badge" >' + row.status + '</span>';

          }
        },
      ],
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4, 5],
          sortable: false
        }
      ],
      drawCallback: function (settings) {
        var api = this.api();
        var startIndex = api.page.info().start;
        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
          cell.innerHTML = startIndex + i + 1;
        });
      }
    });
    return () => {
      table.destroy();
    };
  }, [completeTrips]);
  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">Supplier List</span> </div>
          </div>
        </div>
      </div>


      <div class="main-container">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="dashboard">
                <div class="dashform">
                  <div class="topsearch-area">
                    <div class="row">
                      <div class="col-12 col-lg-3 col-sm-3">
                        <div class="row">
                          <label for="inputText" class="col-sm-4 col-form-label">Customer *</label>
                          <div class="col-sm-8">
                            <input type="text" class="search-area" placeholder="Search" autocomplete="off" value="" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-sm-3">
                        <div class="row">
                          <label for="inputText" class="col-sm-4 col-form-label">Order ID :*</label>
                          <div class="col-sm-7">
                            <select name="orgId" class="form-select orgselect" required="">
                              <option value="">Select</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 col-sm-4">
                        <div class="row">
                          <label for="inputText" class="col-sm-4 col-form-label">Update Stock</label>
                          <div class="col-sm-8">
                            <label class="cred-switch">
                              <input class="cred-switch-input" type="checkbox" />
                              <span class="cred-switch-label" data-on="Yes" data-off="No"></span> <span class="cred-switch-handle"></span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="verical-scrl-wrapper common-table creditnote-tbl" id="style-3">
                  <table class="table table-bordered dataTable" id="dsTable">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Pk</th>
                        <th>Size</th>
                        <th>Sale Qty</th>
                        <th>Credit Qty</th>
                        <th>Price</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI011</td>
                        <td><p class="cut-text">SKITTLES FRUITS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>648</td>
                        <td>-</td>
                        <td>10.99</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI011</td>
                        <td><p class="cut-text">SKITTLES FRUITS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>648</td>
                        <td>-</td>
                        <td>10.99</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI011</td>
                        <td><p class="cut-text">SKITTLES FRUITS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>648</td>
                        <td>-</td>
                        <td>10.99</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI011</td>
                        <td><p class="cut-text">SKITTLES FRUITS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>648</td>
                        <td>-</td>
                        <td>10.99</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                      <tr>
                        <td><label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span> </label></td>
                        <td>SKI010</td>
                        <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                        <td>36</td>
                        <td>45GMS</td>
                        <td>324</td>
                        <td><input type="text" class="form-control" placeholder="1000" /></td>
                        <td>10.99</td>
                        <td>1560.76</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="purchaseftr">
                  <div class="row">
                    <div class="col-6 col-lg-3 col-sm-3">
                      <p class="frtbx">Std Goods : 0.00</p>
                    </div>
                    <div class="col-6 col-lg-3 col-sm-3">
                      <p class="frtbx">VAT Amount : 0.00</p>
                    </div>
                    <div class="col-6 col-lg-3 col-sm-3">
                      <p class="frtbx"> Zero Goods : 1990.00</p>
                    </div>
                    <div class="col-6 col-lg-3 col-sm-3">
                      <p class="frtbx last">Grand Total : 1990.00</p>
                    </div>
                  </div>
                </div>


                
                <div class="creditnoteform">
                  <form>
                    <div class="row mb-3 padd-l-13px">
                      <div class="col-6 col-lg-6 col-sm-6">
                        <div class="row">
                          <div class="col-6 col-lg-12 col-sm-12">
                            <label for="desc" class="col-form-label"><strong>Invoice Address :</strong></label>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Address Line 1 :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Address Line 2 :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label"> City : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="city"
                              id="city" />
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label padd-left-50"> State : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="state"
                              id="state"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label"> Country : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="country"
                              id="country"
                            />
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label padd-left-50"> Post Code : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="postCode"
                              id="postCode"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">VAT :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Notes :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-lg-6 col-sm-6">
                        <div class="row">
                          <div class="col-6 col-lg-12 col-sm-12">
                            <label for="desc" class="col-form-label"><strong>Delivery Address :</strong></label>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Address Line 1 :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Address Line 2 :</label>
                          </div>
                          <div class="col-6 col-lg-9 col-sm-9">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label"> City : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="city"
                              id="city" />
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label padd-left-50"> State : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="state"
                              id="state"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label"> Country : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="country"
                              id="country"
                            />
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label padd-left-50"> Post Code : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input
                              type="text"
                              class="form-control"
                              name="postCode"
                              id="postCode"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label">Shipping Date :</label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input type="date" class="form-control" name="name" id="name" />
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <label for="desc" class="col-form-label padd-left-50"> Job Ref : </label>
                          </div>
                          <div class="col-6 col-lg-3 col-sm-3">
                            <input type="text" class="form-control" name="name" id="name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-lg-12 col-sm-12">
                        <hr />
                      </div>
                      <div class="col-6 col-lg-12 col-sm-12"><a href="#" class="popup-innerbtn-common">Create Credit Note</a></div>
                    </div>
                  </form>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
};

export default CreditNote; 