import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

const ViewCustomer = ({ customerData, accessToken, onClose }) => {
	const [deliveryAddresses, setDeliveryAddresses] = useState([]);
	const [viewDeliveryAddresses, setViewDeliveryAddresses] = useState([]);


	const getAddressById = (id) => {
	    let address = viewDeliveryAddresses.find(item=>item.id == id);
	    $('#view_billing_adr1').val(address.address_line1);
	    $('#view_billing_adr2').val(address.address_line2);
	    $('#view_billing_city').val(address.city);
	    $('#view_billing_country').val(address.country);
	    $('#view_billing_postcode').val(address.post_code);
	};

	const emptyCusomerAddModal = () => {
	    setDeliveryAddresses([]);
	}


	useEffect(() => {
	    $("#neworderTable1 tbody").off("click", ".viewbtn");
	    $("#neworderTable1 tbody").on("click", ".viewbtn", function () {

	      const customer_id = $(this).data("id");
	      console.log(customer_id);
	      const formData = new FormData();
	      formData.append("customer_id", customer_id);


	      const fetchCustomerData = async () => {
	        const apiUrl = `${config.api_url}/ws-get-customer-list-by-id`;
	        try {
	          const response = await fetch(apiUrl, {
	            method: "POST",
	            headers: {
	              'Authorization': `Bearer ${accessToken}`
	            },
	            body: formData,
	          });

	          const data = await response.json();

	          //console.log(data);

	          	let customer = data.customer;
			    let deliveryAddress = data.delivery_addresses;
			    if (customer) {
			      $('#view_customerId').val(customer.code);
			      $('#view_contact_person_name').val(customer.contact_person_name);
			      $('#view_vat').val(customer.vat_number);
			      $('#view_comapny_name').val(customer.company_name);
			      $('#view_email').val(customer.contact_person_email);
			      $('#view_mobile').val(customer.contact_person_mobile);
			      $('#view_addr1').val(customer.billing_address_line1);
			      $('#view_addr2').val(customer.billing_address_line2);
			      $('#view_city').val(customer.billing_city);
			      $('#view_country').val(customer.billing_country);
			      $('#view_postcode').val(customer.billing_postcode);
			      $("#customer_status").html(customer.status 
			        ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>' 
			        : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
			      );
			      setViewDeliveryAddresses(deliveryAddress);
			    }else{
			    	$('#view_customerId').val('');
			      $('#view_contact_person_name').val('');
			      $('#view_vat').val('');
			      $('#view_comapny_name').val('');
			      $('#view_email').val('');
			      $('#view_mobile').val('');
			      $('#view_addr1').val('');
			      $('#view_addr2').val('');
			      $('#view_city').val('');
			      $('#view_country').val('');
			      $('#view_postcode').val('');
			      $("#customer_status").html('');
			      setViewDeliveryAddresses([]);
			    }
	        
	        
	        } catch (error) {
	          console.error("Error fetching supplier data:", error);
	        }
	      };
	      fetchCustomerData();
	    });

	    return () => {
	      $("#neworderTable1 tbody").off("click", ".viewbtn");
	    };
	  }, []);

	return(
		<div className="modal fade" id="view_customer" tabIndex="-1">
		  <div className="modal-dialog modal-xl">
		    <div className="modal-content">
		      <div className="modal-header">
		        <h5 className="modal-title">View Customer</h5>
		        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={emptyCusomerAddModal} aria-label="Close" ></button>
		      </div>
		      <div className="modal-body">
		        <div className="viewcustomer-area">
		          <form id='view_customer_form'>

		        <div className="row">
		            <div className="col-6 col-lg-6 col-sm-6">&nbsp;</div>
		            <div className="col-6 col-lg-6 col-sm-6">
		            <div className="row">
		                  <label htmlFor="inputText" className="col-sm-6" >&nbsp;</label>
		                  <div className="col-sm-6">
		                  <h4 id='customer_status'></h4>
		                  </div>
		                </div>
		              </div>
		              </div>
		          <hr className="devider"/>
		            <div className="row">
		              <div className="col-6 col-lg-6 col-sm-6">
		                
		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Customer ID
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_customerId' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Contact Person Name
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_contact_person_name' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label"  >
		                    VAT
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_vat' disabled />
		                  </div>
		                </div>
		              </div>
		              <div className="col-6 col-lg-6 col-sm-6">
		                
		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Company Name
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_comapny_name' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Email
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_email' disabled />
		                  </div>
		                </div>
		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Mobile
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_mobile' disabled />
		                  </div>
		                </div>
		              </div>
		            </div>

		            <div className="row">
		              <div className="col-6 col-lg-6 col-sm-6">
		                <h3>
		                  <span>Invoice Address </span>{" "}
		                </h3>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Address Line 1
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_addr1' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Address Line 2
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_addr2' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    City
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_city' disabled />
		                  </div>
		                </div>
		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label">
		                    Country
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_country' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label
		                    htmlFor="inputText"
		                    className="col-sm-6 col-form-label"
		                  >
		                    Post Code
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_postcode' disabled />
		                  </div>
		                </div>
		              </div>

		              <div className="col-6 col-lg-6 col-sm-6">
		                <h3>
		                  <span>Billing Address</span>{" "}
		                  <div className="slect-area">
		                   

		                  <div class="dropdown">
		                    <button class="ddbtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
		                      Select Address
		                    </button>
		                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
		                      {viewDeliveryAddresses.map((item, index) => (
                              <li key={item.id} className={item.defaultDelivery === "1" ? "defaultaddress" : ""}>
                                {
                                  item.defaultDelivery == '1' &&
                                   ( <div><span class="badge bg-warning text-dark">Default</span></div>)
                                }
                                
                                <Link className="dropdown-item" href="#" onClick={() => getAddressById(item.id)}>
                                  <span>Address Line 1 - </span> {item.address_line1}<br />
                                  <span>Address Line 2 - </span> {item.address_line2}<br />
                                  <span>City - </span> {item.city}<br />
                                  <span>Country - </span> {item.country}<br />
                                  <span>Post Code - </span> {item.post_code}
                                </Link>
                              </li>
                            ))}
		                    </ul>
		                   </div>
		                  </div>
		                </h3>
		                <div className="row mb-3">
		                  <label  htmlFor="inputText"  className="col-sm-6 col-form-label" > Address Line 1 </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_billing_adr1' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" >
		                    Address Line 2
		                  </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_billing_adr2' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" > City </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_billing_city' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label"> Country </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_billing_country' disabled />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label"> Post Code </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" id='view_billing_postcode' disabled />
		                  </div>
		                </div>
		              </div>
		            </div>

		            <div className="row">
		              <div className="col-6 col-lg-12 col-sm-12">
		                <h3>
		                  <span>Financial</span> <a href="#" className='viewledger'>View Ledger</a>
		                </h3>
		              </div>

		              <div className="col-6 col-lg-6 col-sm-6">
		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" > Balance </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" > Credit Limit </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label"> Avg Days </label>
		                  <div className="col-sm-6">
		                    <input type="text" className="form-control" />
		                  </div>
		                </div>
		              </div>
		              <div className="col-6 col-lg-6 col-sm-6">
		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" >
		                    Currency
		                  </label>
		                  <div className="col-sm-6">
		                  <input className='form-control' name='fCurrency' />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" > New Date </label>
		                  <div className="col-sm-6">
		                    <input type="date" className="form-control" />
		                  </div>
		                </div>

		                <div className="row mb-3">
		                  <label htmlFor="inputText" className="col-sm-6 col-form-label" > Last Transaction </label>
		                  <div className="col-sm-6">
		                    <input type="date" className="form-control" />
		                  </div>
		                </div>
		              </div>
		            </div>
		          </form>
		          <div className="viewcustomer-tbl-area">
		            <h5>Last 12 Month Turnover</h5>
		            <table className="table table-bordered ">
		              <thead>
		                <tr>
		                  <th>&nbsp;</th>
		                  <th>Aug</th>
		                  <th>Sep</th>
		                  <th>Oct</th>
		                  <th>Nov</th>
		                  <th>Dec</th>
		                  <th>Jan</th>
		                  <th>Feb</th>
		                  <th>Mar</th>
		                  <th>Apr</th>
		                  <th>May</th>
		                  <th>Jun</th>
		                  <th>Jul</th>
		                </tr>
		              </thead>
		              <tbody>
		                <tr>
		                  <td>T/O</td>
		                  <td>13010</td>
		                  <td>0</td>
		                  <td>0</td>
		                  <td>0</td>
		                  <td>0</td>
		                  <td>3244</td>
		                  <td>0</td>
		                  <td>2343</td>
		                  <td>0</td>
		                  <td>0</td>
		                  <td>0</td>
		                  <td>0</td>

		                </tr>
		              </tbody>
		            </table>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

	);
}
export default ViewCustomer;