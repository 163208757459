import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import Swal from "sweetalert2";
const accessToken = localStorage.getItem('accessToken');

    export const deleteOrder = async (id, status, newSales, setNewSales) => {
        const formData = new FormData();
        formData.append('status', status);
        formData.append('order_id', id);
        
        const apiUrl = `${config.api_url}/ws-update-sale-status`;
        try {
          const response = await axios.post(apiUrl, formData, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          });

          if (response.data.status === 'success') {
            setNewSales((prevRows) => {
              return prevRows.filter(item => item.ids !== id);
            });
          }
          }   
        catch (error) {
          console.error("Failed to update status:", error);
        }
      }; 
 
    export const createInvoiceClick = (id, newSales, setCreateInvoiceData) =>{
          let data = newSales.find(item => item.ids === id);
          setCreateInvoiceData([
            {
              id: data.ids,
              gross_value: data.order_amount,
              gross_profit: data.gross_profit,
              gross_profit_percent: data.gross_profit_percent,
              customer_name: data.company_name
            }
          ]);
    }

    export const isSalesDeleteClick = (id, newSales, setNotes, setSalesInfoData) =>{
          let data = newSales.find(item => item.ids === id);
          setNotes('');
          setSalesInfoData([
            {
              id: data.ids,
              note : '',
            }
          ]);
    }

    // export const submitCreateInvoice = async (id, notes, newSales,setNewSales, createInvoiceModalRef) =>{
    //     let status = 'Invoice';
    //     const formData = new FormData();
    //     formData.append('status', status);
    //     formData.append('order_id', id);
    //     formData.append('notes', notes);
    //     const apiUrl = `${config.api_url}/ws-update-sale-status`;

        
    //     try {
    //       const response = await axios.post(apiUrl, formData, {
    //         headers: {
    //           'Authorization': `Bearer ${accessToken}`
    //         },
    //       });

    //       if(response.data.status == 'success')
    //       {
    //         var updatedSales = newSales.map(item => 
    //           item.ids == id ? { ...item, status:'Invoice'} : item
    //         );
    //       }
    //       setNewSales(updatedSales);

    //       if (createInvoiceModalRef.current) {
    //         const bsModal = window.bootstrap.Modal.getInstance(createInvoiceModalRef.current);
    //         if (bsModal) bsModal.hide();
    //       }
    //       }   
    //     catch (error) {
    //       console.error("Failed to update status:", error);
    //     }

    // }