import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const PartialGRN = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); 
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `https://apidev.ebusso.com/get_complete_trip_list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setCompleteTrips(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#completeTripsTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: completeTrips,
      columns: [
        { data: null },
        { data: 'date' },
        {
          data: 'trip_number',
          render: function (data, type, row) {
            return '<a href="CloseTripSummary' + row.id + '">' + row.trip_number + '</a>';
          }
        },
        { data: 'driver' },
        { data: 'vehicle', className: "text-center" },
        // { data: 'status', className: "text-center" },
        //{ data: 'sale_status' },

        {
          data: null,
          render: function (data, type, row) {

            return '<span class="btn btn-primary badge " >' + row.status + '</span>';

          }
        },
      ],
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4, 5],
          sortable: false
        }
      ],
      drawCallback: function (settings) {
        var api = this.api();
        var startIndex = api.page.info().start;
        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
          cell.innerHTML = startIndex + i + 1;
        });
      }
    });
    return () => {
      table.destroy();
    };
  }, [completeTrips]);
  return (
    <div>

      <div className="modal fade" id="crn-add-product" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div class="partialbox"><div class="row mb-3">
                <label for="inputText" class="col-sm-4 col-form-label">Search (Code/Name/Unit Barcode)</label>
                <div class="col-sm-5">
                  <input type="text" class="search-area" placeholder="Search" autocomplete="off" value="" />
                </div>
              </div>
                <table class="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>PK</th>
                      <th>Size</th>
                      <th>Unit Barcode</th>
                      <th>Stock</th>
                      <th>F.STK</th>

                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>SKI010</td>
                      <td>Skittles Crazy Sours</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>

                    </tr>
                    <tr>
                      <td>SKI010</td>
                      <td>Skittles Crazy Sours</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>

                    </tr>
                    <tr>
                      <td>SKI010</td>
                      <td>Skittles Crazy Sours</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>

                    </tr>
                    <tr>
                      <td>SKI010</td>
                      <td>Skittles Crazy Sours</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>

                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="savepartial" tabindex="-1">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">&nbsp;</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="popupbx"><h3> Save Partial GRN?</h3>
                <a href="/GRN">Save Partial</a>
                <a href="/PurchaseHistory" class="gre">Close GRN</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />

      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <span class="last-crumb">Partial GRN</span> </div>
          </div>
        </div>
      </div>

      <div class="main-container">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <div class="dashboard">
                <div class="">


                 



                  <div class="dashform">
                  <div class="topsearch-area">

                    <div class="row">
                    <div class="col-12 col-lg-4 col-sm-4">
                      <div class="row">
                        <label for="inputText" class="col-sm-2 col-form-label">Supplier:</label>
                        <div class="col-sm-6">
                        <select name="orgId" class="form-select orgselect" required="">
                            <option value="">Select</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-8 col-sm-8">
                      <div style={{ float: "right" }}>
                      <a href="#" class="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#crn-add-product">Add New Product</a>
                      </div>
                    </div>

                    </div>
                    </div>
                    </div>





                  <div class="verical-scrl-wrapper common-table partial-grn-tbl" id="style-3">
                    <table class="table table-bordered dataTable" id="dsTable">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Expiry</th>
                          <th>Description</th>
                          <th>Pk</th>
                          <th>Size</th>
                          <th>Qty</th>
                          <th>Net Price</th>
                          <th>Value</th>
                          <th>VAT</th>
                          <th>CurStock</th>
                          <th>On S.O</th>
                          <th>FStk</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><a href="/Products">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                        <tr>
                          <td><a href="#">SKI010</a></td>
                          <td><input type="date" class="form-control" /></td>
                          <td><p class="cut-text">SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS SKITTLES CRAZY SOURS</p></td>
                          <td>36</td>
                          <td>45GMS</td>
                          <td><input type="text" class="form-control" placeholder="1000" /></td>
                          <td>10.99</td>
                          <td>3560.76</td>
                          <td>178.00</td>
                          <td>0</td>
                          <td>1000</td>
                          <td>2000</td>
                          <td><a href="#"><i class="fa fa-eye"></i></a>
                            <a href="#"><i class="fa fa-download"></i></a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="purchaseftr">
                    <div class="row">
                      <div class="col-6 col-lg-3 col-sm-3"><p class="frtbx">Std Goods : 4560.00</p></div>
                      <div class="col-6 col-lg-3 col-sm-3"><p class="frtbx">VAT Amount : 228.00</p></div>
                      <div class="col-6 col-lg-3 col-sm-3"><p class="frtbx">Zero Goods : 10681.00</p></div>
                      <div class="col-6 col-lg-3 col-sm-3"><p class="frtbx last">Grand Total : 15469.00</p></div>
                    </div>
                  </div>

                  <div class="purchaseform">
                    <form>
                      <div class="row mb-3 padd-l-13px">
                        <div class="col-6 col-lg-6 col-sm-6">
                          <div class="row mb-3">
                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Date of Arrival :</label>
                            </div>
                            <div class="col-6 col-lg-6 col-sm-6">
                              <input type="date" class="form-control" name="name" id="name" />
                            </div>
                          </div>


                          <div class="row mb-3">
                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Job Ref *:</label>
                            </div>
                            <div class="col-6 col-lg-6 col-sm-6">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>

                          {/* <div class="row mb-3">
                      <div class="col-6 col-lg-6 col-sm-6">
                        <label for="desc" class="col-form-label">Finance *:</label>
                      </div>
                      <div class="col-6 col-lg-6 col-sm-6">
                        <select name="orgId" class="form-select orgselect" required="">
                          <option value="">Discount Type</option>
                        </select>
                      </div>
                    </div> */}

                          {/* <div class="row mb-3">
                      <div class="col-12 col-lg-12 col-sm-12">
						  <table class="table table-bordered dataTable">
							  <thead>
							  	<tr>
								  <th>Type Amt</th>
									<th>Amt</th>
								  </tr>
							  </thead>
							  <tbody>
							  	<tr>
								  <td>Retro </td>
									<td>3.45</td>
								  </tr>
								  <tr>
								  <td>Prompt Payment</td>
									<td>0.98</td>
								  </tr>
								  <tr>
								  <td>&nbsp;</td>
									<td>&nbsp;</td>
								  </tr>
							  </tbody>
						  </table>
                        
                      </div>
                      
                    </div> */}

                          <div class="row mb-3">
                            <div class="col-6 col-lg-3 col-sm-3">
                              <label for="desc" class="col-form-label">Notes :</label>
                            </div>
                            <div class="col-6 col-lg-9 col-sm-9">
                              <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                            </div>
                          </div>


                        </div>
                        <div class="col-6 col-lg-6 col-sm-6">
                          <div class="row mb-3">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Delivery Address (of Warehouse)</label>
                            </div>
                          </div>
                          <div class="row mb-3">


                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Warehouse Name:</label>
                            </div>
                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <label for="desc" class="col-form-label">Code:</label>
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>


                          <div class="row mb-3">
                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Address Line 1 :</label>
                            </div>
                            <div class="col-6 col-lg-6 col-sm-6">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Address Line 2 :</label>
                            </div>
                            <div class="col-6 col-lg-6 col-sm-6">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>






                          <div class="row mb-3">


                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">City :</label>
                            </div>
                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <label for="desc" class="col-form-label">State :</label>
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>


                          <div class="row mb-3">


                            <div class="col-6 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Country :</label>
                            </div>
                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <label for="desc" class="col-form-label">Post Code :</label>
                            </div>

                            <div class="col-6 col-lg-2 col-sm-2">
                              <input type="text" class="form-control" name="name" id="name" />
                            </div>
                          </div>

                        </div>
                        <div class="col-6 col-lg-12 col-sm-12">
                          <hr />
                        </div>
                        <div class="col-6 col-lg-12 col-sm-12"><a href="#" class="popup-innerbtn-common" data-bs-toggle="modal" data-bs-target="#savepartial">Confrm GRN</a></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartialGRN;
