import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Header = () => {
    const [activeLink, setActiveLink] = useState('Dashboard');
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [name, setName] = useState('');
	const location = useLocation(); // use Location for active tab
    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const handleMobileNavToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };
    useEffect(() => {
        const storedName = localStorage.getItem('name');
        setName(storedName || 'No name found');
    }, []);

    // Optional: Function to update localStorage and state
    const updateName = (newName) => {
        localStorage.setItem('name', newName);
        setName(newName);
    };
    return (
        <header id="header" className="header fixed-top" data-scrollto-offset="0">
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo-area align-items-center scrollto me-auto me-lg-0">
                    <img src="/assets/img/logo.jpg" alt="Logo" />
                </a>

                <nav id="navbar" className={`navbar ${mobileNavOpen ? 'mobile-nav-active' : ''}`}>
                    <ul className={`nav-menu ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                        <li>
                            <Link
                                to="/Dashboard"
                                className={location.pathname === `/dashboard` && 'active'}
                                onClick={() => handleSetActiveLink('Dashboard')} 
                            >
                                <span> Dashboard</span>
                            </Link>

                            
                        </li>
                        <li className="dropdown">
                            <Link to="#" className={(location.pathname === '/NewPurchaseOrder' || location.pathname === '/GRN' || location.pathname === '/PurchaseHistory')  && 'active'}>
                                <span>Purchases</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><a href="/NewPurchaseOrder" className={location.pathname === '/NewPurchaseOrder' && 'active'}>
		<span>New Purchase Order</span></a></li>
                                <li><a href="/GRN" className={location.pathname === '/GRN' && 'active'}>
		<span>GRN</span></a></li>
                                <li><a href="/PurchaseHistory" className={location.pathname === '/PurchaseHistory' && 'active'}>
		<span>Purchases History</span></a></li>
                               
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#" className={(location.pathname === '/NewSalesOrder' || location.pathname === '/CurrentSalesOrder' || location.pathname === '/OrderHistory' || location.pathname === '/DeleteOrder' || location.pathname === '/CreditNote' || location.pathname === '#')  && 'active'}>
                                <span>Sales</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="/NewSalesOrder" className={location.pathname === '/NewSalesOrder' && 'active'}><span>New Sales Order</span></Link></li>
                                <li><Link to="/CurrentSalesOrder" className={location.pathname === '/CurrentSalesOrder' && 'active'}>Current Sales Order</Link></li>
                                <li><Link to="/OrderHistory" className={location.pathname === '/OrderHistory' && 'active'}>Order History</Link></li>
                                <li><Link to="/DeleteOrder" className={location.pathname === '/DeleteOrder' && 'active'}>Deleted Orders</Link></li>
                              
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#" className={(location.pathname === '/UserList' || location.pathname === '/Bank' || location.pathname === '/Supplier' || location.pathname === '/Customer' || location.pathname === '/WarehouseMaster' || location.pathname === '/Accounting_Code_Master' || location.pathname === '/Products' || location.pathname === '/ProductCategoryMaster' || location.pathname === '/Brand')  && 'active'}>
                                <span>Masters</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="/UserList" className={location.pathname === '/UserList' && 'active'}><span>User Master</span></Link></li>
                                <li><Link to="/Bank" className={location.pathname === '/Bank' && 'active'}><span>Bank</span></Link></li>
                                <li><Link to="/Supplier" className={location.pathname === '/Supplier' && 'active'}><span>Supplier Master</span></Link></li>
                                <li><Link to="/Customer" className={location.pathname === '/Customer' && 'active'}><span>Customer Master</span></Link></li>
                                <li><Link to="/WarehouseMaster" className={location.pathname === '/WarehouseMaster' && 'active'}><span>Warehouse Master</span></Link></li>
                                <li><Link to="/Accounting_Code_Master" className={location.pathname === '/Accounting_Code_Master' && 'active'}><span>Accounting Code Master</span></Link></li>

                                <li className="dropdown">
                                  <a href="#" className={(location.pathname === '/Products' || location.pathname === '/ProductCategoryMaster' || location.pathname === '/Brand')  && 'active'}>
                                    <span>Product Master</span> 
                                    <i className="bi bi-chevron-right dropdown-indicator"></i></a>
                                    <ul>
                                        <li><Link to="/Products" className={location.pathname === '/Products' && 'active'}><span>Product List</span></Link></li>
                                        <li><Link to="/ProductCategoryMaster" className={location.pathname === '/ProductCategoryMaster' && 'active'}><span>Product Category</span></Link></li>
                                        <li><Link to="/Brand" className={location.pathname === '/Brand' && 'active'}><span>Product Brand</span></Link></li>

                                    </ul>
                                </li>
                                
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#" className={(location.pathname === '/SupplierBill' || location.pathname === '/SupplierCredit' || location.pathname === '/PayBills' || location.pathname === '/PurchaseLedger' || location.pathname === '/SalesLedger' || location.pathname === '/ReceivePayment' || location.pathname === '/JournalEntry')  && 'active'}>
                                <span>Accounts</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>

                                {/* <li><Link to="/SupplierBill"><span>Supplier</span></Link></li> */}
                                <li className="dropdown">
                                    <a href="#" className={(location.pathname === '/SupplierBill' || location.pathname === '/SupplierCredit' || location.pathname === '/PayBills')  && 'active'}>
                                        <span>Supplier</span> <i className="bi bi-chevron-right dropdown-indicator"></i></a>
                                    <ul>
                                        <li><Link to="/SupplierBill" className={location.pathname === '/SupplierBill' && 'active'}><span>Supplier Bill</span></Link></li>
                                        <li><Link to="/SupplierCredit" className={location.pathname === '/SupplierCredit' && 'active'}><span>Supplier Credit</span></Link></li>
                                        <li><Link to="/PayBills" className={location.pathname === '/PayBills' && 'active'}><span>Pay Bills</span></Link></li>

                                    </ul>
                                </li>
                                
                                <li><Link to="/PurchaseLedger" className={location.pathname === '/PurchaseLedger' && 'active'}><span>Purchase Ledger</span></Link></li>
                                <li><Link to="/SalesLedger" className={location.pathname === '/SalesLedger' && 'active'}><span>Sales Ledger</span></Link></li>
                                <li><Link to="/ReceivePayment" className={location.pathname === '/ReceivePayment' && 'active'}><span>Receive Payment</span></Link></li>
                               
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#" className={(location.pathname === '#' || location.pathname === '#' || location.pathname === '#' || location.pathname === '#' || location.pathname === '#' || location.pathname === '#')  && 'active'}>
                                <span>Reports</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Purchase Report</span></Link></li>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Sales Report</span></Link></li>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Stock Report</span></Link></li>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Profit And Loss Report</span></Link></li>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Lead Time Report</span></Link></li>
                                <li><Link to="#" className={location.pathname === '#' && 'active'}><span>Retro Report</span></Link></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#"  className={(location.pathname === '#' || location.pathname === '#' || location.pathname === '/Group_Permissions')  && 'active'}>
                                <span>Settings</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                              
                                <li><Link to="/Group_Permissions" className={location.pathname === '/Group_Permissions'  && 'active'}><span>Group Permissions</span></Link></li>
                                
                            </ul>
                        </li>
                           <Link className="dropdown-item d-flex align-items-center last-li" to="/"><i className="bi bi-box-arrow-right"></i> <span>Sign Out</span></Link>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle d-none" onClick={handleMobileNavToggle}></i>
                </nav>

                <div className="header-nav ms-auto userprofile-area">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src="../assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2"> {name}</span>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                               
                                {/* <li><hr className="dropdown-divider" /></li> */}
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-person"></i> <span>My Profile</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-gear"></i> <span>Account Settings</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-question-circle"></i> <span>Need Help?</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center last-li" to="/"><i className="bi bi-box-arrow-right"></i> <span>Sign Out</span></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
