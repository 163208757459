import React, { useState, useRef } from 'react';

const ResizableTable = () => {
  const [columnWidths, setColumnWidths] = useState([200, 200, 200]); // Initial widths for 3 columns
  const tableRef = useRef(null);

  const handleMouseDown = (index) => {
    const handleMouseMove = (event) => {
      const newWidths = [...columnWidths];
      const newWidth = event.clientX - tableRef.current.getBoundingClientRect().left - 10; // Adjust based on mouse position
      if (newWidth > 100 && newWidth < 1000) { // Set minimum and maximum width
        newWidths[index] = newWidth;
        setColumnWidths(newWidths);
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div>
      <table ref={tableRef} style={{ borderCollapse: 'collapse', width: '100%', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {columnWidths.map((width, index) => (
              <th key={index} style={{ width: `${width}px`, border: '1px solid black', position: 'relative', padding: '8px', textAlign: 'left' }}>
                Column {index + 1}
                <div
                  onMouseDown={() => handleMouseDown(index)}
                  style={{
                    cursor: 'col-resize',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    height: '100%',
                    width: '10px', // Width of the resize handle
                    zIndex: 1,
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {columnWidths.map((width, index) => (
              <td
                key={index}
                style={{
                  width: `${width}px`,
                  border: '1px solid black',
                  padding: '8px', // Add some padding for better appearance
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                  overflow: 'hidden', // Hide overflow
                  textOverflow: 'ellipsis', // Show ellipsis for overflow
                  maxWidth: `${width}px`, // Ensure max width matches the column width
                }}
              >
                Data agdgfgdjf kdhfkdhfkhdkfh khkjhfkdhfkjdhfkjdhfkjdhfk dfldhf agdgfgdjf kdhfkdhfkhdkfh khkjhfkdhfkjdhfkjdhfkjdhfk dfldhf abc {index + 1}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ResizableTable;
