import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import { DatePicker } from "rsuite";
import * as echarts from 'echarts';

const Management_Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statisticsData, setStatisticsData] = useState(null);
    const accessToken = localStorage.getItem('accessToken');

    const [dashInfo, setDashInfo] = useState([]);

    const fetchDashboardData = async () => {
        const apiUrl = `${config.api_url}/ws-get-dashboard-count`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
      
            setDashInfo(data.data);

            
        } catch (error) {
            console.error('API failed: error fetching dashboard', error);
        }
    };

    useEffect(() => {
        const chartDom = document.querySelector("#barChart");
        const barChart = echarts.init(chartDom);

        const chartOptions = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'bar'
            }]
        };

        barChart.setOption(chartOptions);

        // Clean up the chart instance on component unmount

        fetchDashboardData();
        
        return () => {
            barChart.dispose();
        };
    }, []);

    return (
        <div>
            <Header />

            <nav class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12">
                            <a href="/Dashboard"><i class="fa fa-home"></i> Dashboard</a>
                            <span class="last-crumb">Management Dashboard</span> </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <div className="show-order-area dashboard">
                                <div className="dashform">






                                    <div className='row'>
                                        <div className="col-12 col-lg-4 col-sm-4">
                                            <div class="row">


                                                <div class="card-body">
                                                    <h3 class="card-title">Sales

                                                    <div class="salesfilter">
                                                                        <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                                    </div>
                                                    </h3>

                                                    <div class="sales">
                                                        <div className='row'>
                                                            <div class="col-xxl-6 col-md-6">
                                                                <div class="card info-card sales-card">

                                                                    

                                                                    <div class="card-body">
                                                                        <h3 class="card-title">Monthly Sales</h3>

                                                                        <div class="align-items-center">
                                                                            {/* <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                                <i class="bi bi-calendar-day-fill"></i>
                                                                            </div> */}
                                                                            <div>
                                                                                <h6>£ {dashInfo.total_sales ? dashInfo.total_sales : '0'}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-xxl-6 col-md-6">
                                                                <div class="card info-card sales-card">

                                                                    

                                                                    <div class="card-body">
                                                                        <h3 class="card-title">Invoiced till date</h3>

                                                                        <div class="align-items-center">
                                                                            {/* <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                                <i class="bi bi-calendar-day-fill"></i>
                                                                            </div> */}
                                                                            <div>
                                                                                <h6>£ {dashInfo.invoice_till_date ? parseFloat(dashInfo.invoice_till_date).toFixed(2) : '0.00'}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>

                                                </div>
                                            </div></div>
                                        <div className="col-12 col-lg-8 col-sm-8">
                                            <div class="row">


                                                <div class="card-body">
                                                    <h3 class="card-title">Inventory

                                                    <div class="salesfilter">
                                                                        <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                                    </div>
                                                    </h3>
                                                    

                                                    <div class="sales">
                                                        <div className='row'>
                                                            <div class="col-xxl-4 col-md-6">
                                                                <div class="card info-card sales-card">

                                                                    

                                                                    <div class="card-body">
                                                                        <h3 class="card-title">Total Inventory Valuation</h3>

                                                                        <div class="align-items-center">
                                                                            {/* <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                                <i class="bi bi-calendar-day-fill"></i>
                                                                            </div> */}
                                                                            <div>
                                                                                <h6>£ {dashInfo.total_inventory_valuation ? parseFloat(dashInfo.total_inventory_valuation).toFixed(2) : '0.00'}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-xxl-4 col-md-6">
                                                                <div class="card info-card sales-card">

                                                                    

                                                                    <div class="card-body">
                                                                        <h3 class="card-title">Free Stock Valuation </h3>

                                                                        <div class="align-items-center">
                                                                            {/* <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                                <i class="bi bi-calendar-day-fill"></i>
                                                                            </div> */}
                                                                            <div>
                                                                                <h6>£ {dashInfo.free_stock_valuation ? parseFloat(dashInfo.free_stock_valuation).toFixed(2) : '0.00'}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-xxl-4 col-md-6">
                                                                <div class="card info-card sales-card">


                                                                    <div class="card-body">
                                                                        <h3 class="card-title">On S.O.</h3>

                                                                        <div class="align-items-center">
                                                                            {/* <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                                <i class="bi bi-calendar-day-fill"></i>
                                                                            </div> */}
                                                                            <div>
                                                                                <h6>£ {dashInfo.total_sales_count ? parseFloat(dashInfo.total_sales_count).toFixed(2) : '0.00'}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>

                                                </div>
                                            </div></div>

                                    </div>

                                    <div className='row' style={{ marginTop: '35px' }}>
                                        <div className="col-12 col-lg-12 col-sm-12">
                                            <div class="echartbox card">
                                                <div className='card-body'>

                                                    <div id="barChart" style={{ minHeight: '400px' }} class="echart"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='sopobx'>
                                        <div className='row'>
                                            <div className="col-12 col-lg-3 col-sm-3">
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-12 col-sm-12'>
                                                                <h3 className='card-title'>Sales Order</h3>
                                                                <div className='count'>
                                                                    <p>Total S.O</p>
                                                                    <h4>{dashInfo.sales_orders_list ? dashInfo.sales_orders_list.length : '0'}</h4>
                                                                </div>
                                                                <hr />
                                                                <div className='tblbx'>
                                                                <div className='verical-scrl-wrapper-small'>
                                                                <table className='table table-bordered dataTable'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Customer</th>
                                                                            <th>SOs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            dashInfo.sales_orders_list && dashInfo.sales_orders_list.length > 0 ? (
                                                                                dashInfo.sales_orders_list
                                                                                    .filter(row => row.company_name && row.company_name.trim() !== '') 
                                                                                    .map((row, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{row.company_name}</td>
                                                                                            <td>{row.order_count}</td>
                                                                                        </tr>
                                                                                    ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="2" className="text-center">
                                                                                        No data available.
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        

                                                                        
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                            <div className="col-12 col-lg-3 col-sm-3">
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-12 col-sm-12'>
                                                                <h3 className='card-title'>Purchase Order</h3>
                                                                <div className='count'>
                                                                    <p>Total P.O</p>
                                                                    <h4>{dashInfo.purchase_orders_list ? dashInfo.purchase_orders_list.length : '0'}</h4>
                                                                </div>
                                                                <hr />
                                                                <div className='tblbx'>
                                                                <div className='verical-scrl-wrapper-small'>
                                                                <table className='table table-bordered dataTable'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Supplier</th>
                                                                            <th>SOs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dashInfo.purchase_orders_list && dashInfo.purchase_orders_list.length > 0 ? (
                                                                                dashInfo.purchase_orders_list
                                                                                    .filter(row => row.name && row.name.trim() !== '') // Filter out rows with empty or whitespace-only names
                                                                                    .map((row, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{row.name}</td>
                                                                                            <td>{row.order_count}</td>
                                                                                        </tr>
                                                                                    ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="2" className="text-center">
                                                                                        No data available.
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Booking</h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <p>Total Number of Jobs Booked</p>
                                                                    <hr />
                                                                    <span>145</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <p>Total Number of Jobs Booked</p>
                                                                <hr />
                                                                <span>36</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Management_Dashboard;
