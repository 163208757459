import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import ViewProduct from "../Master/ViewProduct";
import SupplierSearch from "../Purchase/SupplierSearch";
import ProductBatch from "../Purchase/ProductBatch";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";

//fetchSupplierData
import {
  fetchProductData,
  fetchWarehouseDeliveryData,
  changeWarehouseAddress,
  handleInputChange,
  removeRow,
  calculateValue,
  acceptNumber,
  getAddressById,
  handleChange1,
  handleInputChangeInSalesNew,
  handleInputChangeInPOSalesNew,
  changeCustomerAddress,
  fetchCustomerDeliveryData

} from "../../services/NewPurchaseOrderService";

const CreatePO = () => {
  const { id } = useParams();
  const [supplierData, setSupplierData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [discTableRows, setDiscTableRows] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [warehouseDeliveryAddress, setWarehouseDeliveryAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [productBatch, setProductBatch] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [productId, setProductId] = useState(0);
  const navigateToGRNList = useNavigate();
  const discModal = useRef(null);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [picker, setSelectedProduct] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const productBatchRef = useRef(null);

  const [salesInfo, setSalesInfo] = useState([]);

  

  const handleSoInputChange = (e) => {
      const { name, value } = e.target;
      setSalesInfo((prevSalesInfo) => ({
          ...prevSalesInfo,
          [name]: value,
      }));
  };

  const [totalVal, setTotalVal] = useState({
    stdGoods: 0,
    zeroGoods: 0,
    vatAmt: 0,
    grandTotal: 0,
    totalItems : 0,
    grossWeight : 0,
    netWeight : 0,
    cbm : 0,
    totalProfitPercent : 0,
    totalProfit : 0,

    netstdGoods: 0,
    netzeroGoods: 0,
    netvatAmt: 0,
    netgrandTotal: 0

  });

   useEffect(() => {
    const calculateTotals = () => {
      const stdGoods = tableRows
        .filter(item => item.vat != '0.00' && item.vat != null)
        //.reduce((sum, item) => sum + (parseFloat(item.cost) * parseFloat(item.pdtqty)), 0);
        .reduce((sum, item) => sum + (parseFloat(item.value)), 0);

      const zeroGoods = tableRows
        .filter(item => item.vat == '0.00' || item.vat == null)
        //.reduce((sum, item) => sum + (parseFloat(item.cost) * parseFloat(item.pdtqty)), 0);
        .reduce((sum, item) => sum + (parseFloat(item.value)), 0);

      const vatAmt = tableRows.reduce((sum, item) => {
        const itemVat = (parseFloat(item.value) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      const grandTotal = stdGoods + zeroGoods + vatAmt;



      const netstdGoods = tableRows
        .filter(item => item.vat != '0.00' && item.vat != null)
        //.reduce((sum, item) => sum + (parseFloat(item.cost) * parseFloat(item.pdtqty)), 0);
        .reduce((sum, item) => sum + (parseFloat(item.netp) * item.pdtqty), 0);

      const netzeroGoods = tableRows
        .filter(item => item.vat == '0.00' || item.vat == null)
        //.reduce((sum, item) => sum + (parseFloat(item.cost) * parseFloat(item.pdtqty)), 0);
        .reduce((sum, item) => sum + (parseFloat(item.netp) * item.pdtqty), 0);

      const netvatAmt = tableRows.reduce((sum, item) => {
        const itemVat = ((parseFloat(item.netp) * item.pdtqty) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      const netgrandTotal = netstdGoods + netzeroGoods + netvatAmt;






      const totalItems = tableRows.reduce((sum, item)=>{
        const itemCount = parseInt(item.pdtqty);
        return sum + itemCount;
      }, 0);

      const grossWeight = tableRows.reduce((sum, item) => {
        const grossWeightCount = parseInt(item.pdtqty) * parseFloat(item.grossWeight);
        return sum + grossWeightCount;
      }, 0);

      const netWeight = tableRows.reduce((sum, item) => {
        const netWeightCount = parseInt(item.pdtqty) * parseFloat(item.netWeight);
        return sum + netWeightCount;
      }, 0);

      const cbm = tableRows.reduce((sum, item) => {
        const cbmCount = parseInt(item.pdtqty) * parseFloat(item.cbm);
        return sum + cbmCount;
      }, 0);

      let totalProfitPercent = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.pdtqty)), 0);
      totalProfitPercent = totalProfitPercent / (tableRows.length);
      //setGrossProfitPercent(totalProfitPercent);


      const totalProfit = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.pdtqty)), 0);
      //setGrossProfit(totalProfit);



      setTotalVal({
        stdGoods,
        zeroGoods,
        vatAmt,
        grandTotal,
        totalItems,
        grossWeight,
        netWeight,
        cbm,
        totalProfit,
        totalProfitPercent,

        netstdGoods,
        netzeroGoods,
        netvatAmt,
        netgrandTotal,
      });
    };

    // Call the function to calculate totals whenever tableRows changes
    calculateTotals();
  }, [tableRows]);

  const [modalData, setModalData] = useState(null);
  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();
    }
  };

  const [formData, setFormData] = useState({
    retro: "",
    promptPayment: "",
    costToServe: "",
    offInvoice: "",
    bedsLeds: "",
    sugroRetro: "",
  });

  const handleDiscount = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


     useEffect(() => {
    $('#dsTable tbody').off('click', '.applybtn');
    $('#dsTable tbody').on('click', '.applybtn', function () 
    {
      $('#freestock').empty();
      $('#stock').empty();
      $('#on_so').empty();
      $('#allocation').empty();
      $('#onpf').empty();
      $('#onpo').empty();
      const product_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', product_id);
      document.getElementById('viewProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $('#code').val(data.products[0].product_desc);
          $('#packValue').val(data.products[0].pack);
          $('#vat').val(data.products[0].vat);
          $('#weight').val(data.products[0].gross_weight);
          $('#supplier').val(data.products[0].supplier_name);
          $('#description').val(data.products[0].product_desc);
          $('#sizeValue').val(data.products[0].size);
          $('#barcode').val(data.products[0].unit_barcode);
          $('#view_case_barcode').val(data.products[0].case_barcode);
          $('#pallet_qty').val(data.products[0].pallet_qty);
          $('#case_price').val(data.products[0].price);
          $("#product_status").html(data.products[0].status != 0
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
          );
          $('#viewcbm').val(data.products[0].cbm);
          $('#brandname').val(data.products[0].brand);
          $('#categooryName').val(data.products[0].category);
          $('#editnetWeight').val(data.products[0].net_weight);
          $('#editgrossWeight').val(data.products[0].gross_weight);
          $('#freestock').append(data.freestock == null ? 0 : data.freestock);
          $('#stock').append(parseInt(data.products[0].quantity));
          $('#on_so').append(data.onso == null ? 0 : data.onso);
          $('#allocation').append(data.allocation == null ? 0 : data.allocation);
          $('#onpf').append(data.pf == null ? 0 : data.pf);
          $('#onpo').append(data.on_purchase == null ? 0 : data.on_purchase);
        } 
        catch (error) 
        {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $('#dsTable tbody').off('click', '.applybtn');
    };
  }, []);

  const handleShowPastPurchaseModal = (showModalHandler) => {
    showModalHandler();
  };

  useEffect(() => {
    fetchProductData(setProductData);
    fetchWarehouseDeliveryData(setWarehouseDeliveryAddress);
  }, []);

  const handleSubmit = async (e, tableRows, productId) => {
    e.preventDefault();
  
    let a = 0;
    const retro = parseFloat(formData.retro) || 0;
    const prompt_payment = parseFloat(formData.promptPayment) || 0;
    const cost_to_serve = parseFloat(formData.costToServe) || 0;
    const off_invoice = parseFloat(formData.offInvoice) || 0;
    const sugroRetro = parseFloat(formData.sugroRetro) || 0;
    const bedsLeds = parseFloat(formData.bedsLeds) || 0;
  
    let total = retro + prompt_payment + cost_to_serve + off_invoice + sugroRetro + bedsLeds;

    const initializedItems = tableRows.map((item) => {
      if (item.id !== productId || (item.netp - 1 )  < total) {
        return item; 
        console.log(item.value);
      }
  
      a = 1; 
      return {
        ...item,
        netp: item.netp - retro - prompt_payment - cost_to_serve - off_invoice - bedsLeds - sugroRetro,
        retro: retro,
        promptPayment: prompt_payment,
        costToServe: cost_to_serve,
        offInvoice: off_invoice,
        bedsLeds: bedsLeds,
        sugroRetro: sugroRetro
      };
    });
  
    if (a === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please enter discount less than Net Price",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      setTableRows(initializedItems); 
    }
  
    setFormData({
      retro: "",
      promptPayment: "",
      costToServe: "",
      offInvoice: "",
      bedsLeds: "",
      sugroRetro: "",
    });
  
    const bsModal = window.bootstrap.Modal.getInstance(discModal.current);
    if (bsModal) bsModal.hide();
  };
  

  useEffect(() => {
    const newGrandTotal = tableRows.reduce(
      (total, row) => total + parseFloat(row.netp) || 0,
      0
    );
    setGrandTotal(newGrandTotal);

    
    const totalVat = tableRows.reduce((vat, row) => {
      const rowValue = parseFloat(row.netp) || 0;
      // const totalDeductions =
      //   (parseFloat(row.promptPayment) || 0) +
      //   (parseFloat(row.costToServe) || 0) +
      //   (parseFloat(row.offInvoice) || 0) +
      //   (parseFloat(row.bedsLeds) || 0) +
      //   (parseFloat(row.sugroRetro) || 0);

      const vatRate = parseFloat(row.vat) || 0;
      // Compute VAT for this row
      const rowVat = ((rowValue) / 100) * vatRate;

      return vat + rowVat ;
    }, 0); // Initialize accumulator to 0

    setVatAmt(totalVat);

    const stdGoods = tableRows.reduce((total, row) => {
      if (parseFloat(row.vat) > 0) 
        {
          return total + row.netp;
        }
      return total; 
    }, 0);
    
    setStdGoods(stdGoods);

    const zeroGoods = tableRows.reduce((total, row) => {
      if (parseFloat(row.vat) == 0) {
        return total + row.netp;
      }
      return total; 
    }, 0);
    setZeroGoods(zeroGoods);


  }, [tableRows]);

  useEffect(() => {
    handleEditData(id);
  }, []);

  const handleEditData = async (id) => {
    
    const  formData = new FormData();
    formData.set('sale_id', id);
    const response = await axios.post(`${config.api_url}/ws-get-posales-by-id`, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      }
    }); 
    const data = await response.data;
    console.log(data);

    setSalesInfo(data.sale);

    if (data.product && data.product.length > 0) {
      const newRows = data.product.map(item => ({
       

        id: item.id,
        code: item.code,
        product_desc: item.product_desc,
        pack: item.pack,
        size: item.size,
        price: parseFloat(item.price),
        pdtqty : parseInt(item.salesQty),
        cost: parseFloat(item.cost),
        quantity: parseInt(item.quantity),
        taxrate: parseFloat(item.item_tax) / parseFloat(item.price) * 100, 
        vat: parseFloat(item.vat),  
        value: item.salesQty * item.cost,
        profit: parseFloat(item.price) - parseFloat(item.cost),
        sales_item_id : item.sales_item_id,
        receivedQty : item.receivedQty ? item.receivedQty : 0 ,
        mappedPO : item.mappedPO ? item.mappedPO : 0,
        Onpo : item.Onpo ? item.Onpo : 0,
        grossWeight: item.gross_weight,
        netWeight: item.net_weight,
        cbm: item.cbm,
        batch_id : item.batch_id ? item.batch_id : 0,
        purchase_id : item.purchase_id ? item.purchase_id : 0,
        discAmt : 0,
        sys_quantity: item.quantity,
        sale_qty: item.sale_qty ? item.sale_qty : 0,
        //free_stock: data.quantity,
        free_stock : item.freestock ? item.freestock : 0,
        onpurchase : item.on_purchase ? item.on_purchase : 0,
        //netp : item.salesQty * item.cost,
        netp :  item.cost,


      }));

      // Update table rows
      setTableRows(prevRows => [...prevRows, ...newRows]);

    } else {
      console.error('No product data found.');
    }

  };

  const handleProductChange = (inputValue) => {
    setSelectedProduct(inputValue);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setProductInputValue(selectedOption);
      handleChange1(selectedOption.value, tableRows, setTableRows);
    } else {
      setProductInputValue(null);
      console.warn("Selected option is invalid or missing a value");
    }
  };

  const getProductBatchData = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      setProductBatch(data.batch);
      handleOpenPastPurchaseModal(data.batch);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const submitPurchaseOrderForm = async () => {
    const form = document.getElementById("newPurchaseForm");
    const formData = new FormData(form);
    formData.append(`salesId`, id);
    const supplierId = formData.get("supplier_id");
    const delivery_id = formData.get("delivery_id");
    console.log(delivery_id);

    if (supplierId) {
    } else {
      Swal.fire({
        title: "Error!",
        text: "Supplier is Required",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    if (delivery_id == "") {
    
      Swal.fire({
        title: "Error!",
        text: "Delivery Address is required",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    const hasValidProduct = tableRows.some((row) => {
      const pdtqty = parseFloat(row.pdtqty);
      return !isNaN(pdtqty) && pdtqty > 0;
    });

    if (!tableRows.length || !hasValidProduct) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one product and ensure the quantity is greater than zero.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    tableRows.forEach((row, index) => {
      let DiscAmt = row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds +  row.sugroRetro + row.retro;
      formData.append(`code[${index}]`, row.code);
      formData.append(`expiryDate[${index}]`, row.expiryDate);
      formData.append(`value[${index}]`, row.cost * row.pdtqty);
      formData.append(`Disc[${index}]`, DiscAmt ? DiscAmt : 0);
      formData.append(`NetP[${index}]`, row.netp);
      formData.append(`Vat[${index}]`, ((row.value / 100) * row.vat).toFixed(2) );      
      formData.append(`product_id[${index}]`, row.id);

      formData.append(`retro[${index}]`, row.retro);
      formData.append(`promptPayment[${index}]`, row.promptPayment);
      formData.append(`costToServe[${index}]`, row.costToServe);
      formData.append(`offInvoice[${index}]`, row.offInvoice);
      formData.append(`bedsLeds[${index}]`, row.bedsLeds);
      formData.append(`sugroRetro[${index}]`, row.sugroRetro);
    });

    try {
      const response = await axios.post(
        `${config.api_url}/ws-purchases-addPO`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const result = response.data;

      console.log(result);
      Swal.fire({
        title: "Success!",
        text: "PO submitted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      navigateToGRNList("/GRN");
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  useEffect(() => {
    
    fetchWarehouseDeliveryData(setWarehouseDeliveryAddress);
    fetchCustomerDeliveryData(setCustomerDeliveryAddress);
  }, []);

  const [customerAddress, setCustomerAddress] = useState([]);
  const [isDelivery, setIsDelivery] = useState('delivery');
   const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState([]);
   
  const handleDiscClick = (id) => {
    setProductId(id);
    let data = tableRows.find(item => item.id === id);
    if(data.bedsLeds || data.costToServe || data.offInvoice || data.retro || data.sugroRetro || data.promptPayment ) 
    {
      setFormData({
        retro: data.retro || 0,
        promptPayment: data.promptPayment || 0,
        costToServe: data.costToServe || 0,
        offInvoice: data.offInvoice || 0,
        bedsLeds: data.bedsLeds || 0,
        sugroRetro: data.sugroRetro || 0,
      });
    }
    else{
      setFormData({
        retro: "",
        promptPayment: "",
        costToServe: "",
        offInvoice: "",
        bedsLeds: "",
        sugroRetro: "",
      });
    }
  };

   const handleDeliveryandCollectionAddress = (event) => {


    setDeliveryAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city:'',
        state:'',
        country:'',
        postcode:'',

        });  
    setCustomerAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city:'',
        state:'',
        country:'',
        postcode:'',

        }); 
    setIsDelivery(event.target.value);
    
  };

  const handleWarehouseandCustomerAddress = (event) => { 

  
    setIsDelivery(event.target.value);
    setDeliveryAddress([]);   
    setCustomerAddress([]);  

  };

  return (
    <div>
      <div className="modal fade" id="ON-PO" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">(SKIO11) SKITTLES FRUITS</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>
                  S.O: 12714 <span>D&D Snacks (D&D023)</span>
                </h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Assign</th>
                      <th>Order Qty</th>
                      <th>On P.O</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className=""
                          type="radio"
                          name="gridRadios"
                          value="option1"
                          defaultChecked
                        />
                      </td>
                      <td>500</td>
                      <td>1000</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="partialbox">
                <h3>
                  S.O: 12800 <span>TS Chilled Food (TSC004)</span>
                </h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Assign</th>
                      <th>Order Qty</th>
                      <th>On P.O</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className=""
                          type="radio"
                          name="gridRadios"
                          value="option1"
                          defaultChecked
                        />
                      </td>
                      <td>500</td>
                      <td>1000</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="partialbox">
                <h3>
                  S.O: 12802 <span>AAC & Co (AAC001)</span>
                </h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Assign</th>
                      <th>Order Qty</th>
                      <th>On P.O</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className=""
                          type="radio"
                          name="gridRadios"
                          value="option1"
                          defaultChecked
                        />
                      </td>
                      <td>500</td>
                      <td>1000</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="adnw-prct" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>Search (Code/Description/Unit Barcode)</h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Description</th>
                      <th>PK</th>
                      <th>Size</th>
                      <th>Unit Barcode</th>
                      <th>Stock</th>
                      <th>F.Stk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductBatch
        onViewPastPurchase={(showModal) => {
          productBatchRef.current = showModal;
        }}
        getProductBatchData={getProductBatchData}
        modalData={modalData}
      />

      <div className="modal fade" id="viw-disk" tabIndex="-1" ref={discModal}>
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title">&nbsp; Add Discount</h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="adddiscount-popup">
                <form
                  onSubmit={(e) => handleSubmit(e, tableRows, productId)}
                  id="addDiscountForm"
                >
                  <table className="table table-bordered dataTable">
                    <tbody>
                      <tr>
                        <td>Retro</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="retro"
                            value={formData.retro}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Prompt Payment</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="promptPayment"
                            value={formData.promptPayment}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Cost to Serve</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="costToServe"
                            value={formData.costToServe}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Off Invoice</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="offInvoice"
                            value={formData.offInvoice}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Beds & Leds</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="bedsLeds"
                            value={formData.bedsLeds}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Sugro Retro</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="sugroRetro"
                            value={formData.sugroRetro}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    className="popup-innerbtn-common"
                    type="submit"
                    style={{ marginTop: "10px" }}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Header />
      <ViewProduct />
      <form id="newPurchaseForm">
        <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                {" "}
                <a href="#">
                  <i className="fa fa-home"></i>
                </a>{" "}
                &gt; <a href="dashboard">Dashboard</a> &gt;{" "}
                <span className="last-crumb">Create P.O.</span>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="main-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div className="dashboard">
                  <div className="">
                    <div className="dashform">
                      <div className="topsearch-area">
                        <div className="row">
                          <div className="col-12 col-lg-3 col-sm-3">
                            <div className="row">
                              <label
                                htmlFor="inputText"
                                className="col-sm-4 col-form-label"
                              >
                                Supplier:
                              </label>
                              <div className="col-sm-8">
                                <SupplierSearch />
                              </div>
                            </div>
                          </div>
                          {/*<div className="col-12 col-lg-3 col-sm-3">
                            <div className="row">
                              <label
                                htmlFor="inputText"
                                className="col-sm-4 col-form-label"
                              >
                                Product:
                              </label>
                              <div className="col-sm-8">
                                <Select
                                  isClearable
                                  onInputChange={handleProductChange}
                                  onChange={handleChange}
                                  options={productData}
                                  placeholder="Select Product"
                                  value={productInputValue}
                                />
                              </div>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                    </div>
                    <div className="verical-scrl-wrapper common-table purchase-no-tbl" id="style-3" >
                      <table className="table table-bordered dataTable" id="dsTable" >
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Pk</th>
                            <th>Size</th>
                            <th>Qty</th>
                            <th>CPrice</th>                          
                            <th>Value</th>
                            <th>Disc</th>
                            <th>NetP</th>
                            <th>VAT</th>
                            <th>CurStock</th>
                            <th>On S.O</th>
                            <th>FStk</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableRows.length === 0 ? (
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan={14}>No product added</td>
                            </tr>
                          ) : (
                            tableRows.map((row) => {

                              const net_price = parseFloat(row.netp) || 0;
                              const value = parseFloat(row.value) || 0;
                              const retro = parseFloat(row.retro) || 0;
                              const promptPayment = parseFloat(row.promptPayment) || 0;
                              const costToServe = parseFloat(row.costToServe) || 0;
                              const offInvoice = parseFloat(row.offInvoice) || 0;
                              const bedsLeds = parseFloat(row.bedsLeds) || 0;
                              const sugroRetro = parseFloat(row.sugroRetro) || 0;
                              //const vat = parseFloat(row.vat) || 0;
                              const vat = (parseFloat(row.value) * (parseFloat(row.vat) / 100)) || 0
                              const totalCosts = promptPayment + costToServe + offInvoice + bedsLeds +  sugroRetro + retro;

                              const vatAmount = (((net_price) / 100) * vat ).toFixed(2);
                              const fstk = (Number(row.sys_quantity) || 0) + (Number(row.free_stock) || 0);

                              return (
                                <tr key={row.id}>
                                  <td>
                                   
                                    <input type="hidden" name="vatPercentage[]"  value={row.vat}  />
                                    {/* 
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        getProductBatchData(row.id);
                                      }}
                                    >
                                      {row.code}
                                    </Link>*/}

                                    {row.code}
                                  </td>
                                  <td>
                                    <p className="cut-text">
                                      <a
                                        data-bs-toggle="modal"
                                        className="applybtn"
                                        data-id={row.id}
                                        data-bs-target="#viewproduct"
                                      >
                                        {row.product_desc}
                                      </a>
                                    </p>
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="packsize[]"
                                      value={row.pack}
                                    />{" "}
                                    {row.pack}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="productSize[]"
                                      value={row.size}
                                    />{" "}
                                    {row.size}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="qty[]"
                                       value={row.pdtqty}
                                      className="form-control qtyinputbox"
                                      onChange={(e) => handleInputChangeInPOSalesNew(e, row.id, "pdtqty", setTableRows)}
                                      autoComplete='off'
                                    />
                                  </td>
                                  <td>
                                   
                                  <input
                                    type="number"
                                    name="cost[]"
                                    className="form-control qtyinputbox"
                                    value={row.cost}
                                    onChange={(e) => handleInputChangeInPOSalesNew(e, row.id, "cost", setTableRows)}
                                    step="0.01" // Allow decimal input
                                    autoComplete='off'
                                  />
                                  </td>
                                 
                                  <td>
                                    
                                    {value.toFixed(2)}
                                  </td>
                                  <td>
                                    
                                    <a
                                      href="#"
                                      data-id={row.id}
                                      onClick={() => handleDiscClick(row.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#viw-disk"
                                    >

                                      {totalCosts.toFixed(2)}
                                    </a>
                                  </td>
                                  <td>

                                    <input type="hidden" name="profit[]" value={(((row.price - row.cost) * (row.pdtqty)).toFixed(2)) - totalCosts.toFixed(2)} />
                                     {/*(((row.price - row.cost) * (row.pdtqty)).toFixed(2)) - totalCosts.toFixed(2)*/}
                                    {row.netp}
                                  </td>
                                  <td>
                                   

                                    
                                    {/*((row.netp / 100) * row.vat).toFixed(2)*/}
                                  {parseFloat(row.value) * (parseFloat(row.vat) / 100)}

                                  </td>
                                  <td>
                                    <input type="hidden" name="currrentStock[]" />
                                    {row.quantity ? row.quantity : 0}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="onSO[]"
                                      value="0"
                                    />
                                  
                                      {row.sale_qty}
                               
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="fstk[]"
                                      value="0"
                                    />
                                    {/*(Number(row.freestock)) + (Number(row.quantity)) */}
                                    {fstk}
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      value="Remove"
                                      onClick={() =>
                                        removeRow(row.id, setTableRows)
                                      } >
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>

                      <input
                          type="hidden"
                          name="std_goods"
                          value={totalVal.stdGoods.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="zero_goods"
                          value={totalVal.zeroGoods.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="vat_amount"
                          value={totalVal.vatAmt.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="grand_total"
                          value={totalVal.grandTotal.toFixed(2)}
                        />


                        <input
                          type="hidden"
                          name="net_std_goods"
                          value={totalVal.netstdGoods.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="net_zero_goods"
                          value={totalVal.netzeroGoods.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="net_vat_amount"
                          value={totalVal.netvatAmt.toFixed(2)}
                        />
                        <input
                          type="hidden"
                          name="net_grand_total"
                          value={totalVal.netgrandTotal.toFixed(2)}
                        />
                        
                    </div>
                    <div className="purchaseftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Std Goods :{" "}
                            <span id="std_goods">{totalVal.stdGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            VAT Amount :{" "}
                            <span id="vat_amt">{totalVal.vatAmt.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Zero Goods :{" "}
                            <span id="zero_goods">{totalVal.zeroGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx last">
                            {" "}
                            Gross Total :{" "}
                            <span id="grand_total">
                              {totalVal.grandTotal.toFixed(2)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="purchaseftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Net Std Goods :{" "}
                            <span id="std_goods">{totalVal.netstdGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            VAT Amount :{" "}
                            <span id="vat_amt">{totalVal.netvatAmt.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Zero Goods :{" "}
                            <span id="zero_goods">{totalVal.netzeroGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx last">
                            {" "}
                            Gross Total :{" "}
                            <span id="grand_total">
                              {totalVal.netgrandTotal.toFixed(2)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>






                    <div className="purchaseform">
                      <div className="row mb-3 padd-l-13px">
                        <div className="col-6 col-lg-6 col-sm-6">
                          <div className="row mb-3">
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">
                                      Date of Arrival
                                  </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <input
                                      type="date"
                                      className="form-control"
                                      name="arrival_date"
                                      id="arrival_date"
                                  />
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label padd-left-50">
                                      Job Ref 
                                  </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="job_ref"
                                      id="job_ref"
                                      required
                                  />
                              </div>
                          </div>

                          <div className="row mb-3">
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <label className="col-form-label padd-left-50">
                                      Delivery
                                  </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <input
                                      type="radio"
                                      name="delivery_type"
                                      id="delivery_type"
                                      value="delivery"
                                      defaultChecked
                                      onChange={(e) => handleDeliveryandCollectionAddress(e)}
                                      style={{ float: "left" }}
                                  />
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <label htmlFor="collection" className="col-form-label padd-left-50">
                                      Collection
                                  </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                  <input
                                      type="radio"
                                      name="delivery_type"
                                      id="collection_type"
                                      value="collection"
                                      onChange={(e) => handleDeliveryandCollectionAddress(e)}
                                  />
                              </div>
                          </div>

                          {(isDelivery === 'delivery' || isDelivery === 'customer' || isDelivery === 'warehouse') && (
                              <div className="row mb-3">
                                  <div className="col-3 col-lg-3 col-sm-3">
                                      <label htmlFor="desc" className="col-form-label padd-left-50">
                                          Warehouse Address
                                      </label>
                                  </div>
                                  <div className="col-3 col-lg-3 col-sm-3">
                                      <input
                                          className=""
                                          onChange={handleWarehouseandCustomerAddress}
                                          defaultChecked
                                          type="radio"
                                          name="handleWarehouseandCustomerAddress"
                                          id="handleWarehouseandCustomerAddress"
                                          value="warehouse"
                                      />
                                  </div>
                                  <div className="col-3 col-lg-3 col-sm-3">
                                      <label htmlFor="desc" className="col-form-label padd-left-50">
                                          Customer Address
                                      </label>
                                  </div>
                                  <div className="col-3 col-lg-3 col-sm-3">
                                      <input
                                          className=""
                                          onChange={handleWarehouseandCustomerAddress}
                                          type="radio"
                                          name="handleWarehouseandCustomerAddress"
                                          id="handleWarehouseandCustomerAddress"
                                          value="customer"
                                      />
                                  </div>
                              </div>
                          )}

                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                                <label htmlFor="desc" className="col-form-label">
                                    Notes
                                </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                                <textarea
                                    className="form-control"
                                    name="notes"
                                    id="notes"
                                    style={{ height: "200px" }}
                                ></textarea>
                            </div>
                          </div>
                        </div>

                        {(isDelivery === 'delivery' || isDelivery === 'warehouse' || isDelivery === 'customer') && (
                            <div className="col-6 col-lg-6 col-sm-6">
                                {(isDelivery === 'warehouse' || isDelivery === 'delivery') && (
                                    <div className="row mb-3">
                                        <div className="col-6 col-lg-3 col-sm-3">
                                            <label htmlFor="desc" className="col-form-label">
                                                Delivery Address *
                                            </label>
                                        </div>
                                        <div className="col-6 col-lg-9 col-sm-9">
                                            <select
                                                name="delivery_id"
                                                id="delivery_id"
                                                className="form-select orgselect"
                                                onChange={(event) =>
                                                    changeWarehouseAddress(event, setDeliveryAddress, warehouseDeliveryAddress)
                                                }
                                            >
                                                <option value="">Select Address</option>
                                                {warehouseDeliveryAddress.map((item, index) => (
                                                    <option key={index} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                )}

                                {isDelivery === 'customer' && (
                                    <>
                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label">
                                                    Customer Address *
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-9 col-sm-9">
                                                <select
                                                  name="delivery_id"
                                                  id="delivery_id"
                                                  className="form-select orgselect"
                                                  onChange={(event) =>
                                                      changeCustomerAddress(event, setCustomerAddress, customerDeliveryAddress)
                                                  }
                                              >
                                                    <option value="">Select Address</option>
                                                    {customerDeliveryAddress.map((item, index) => (
                                                        <option key={index} value={item.id}>
                                                            {item.address_line1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="addressLine1" className="col-form-label">
                                                    Address Line 1
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-9 col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="addressLine1"
                                                    id="addressLine1"
                                                    value={customerAddress.address_line1}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="addressLine2" className="col-form-label">
                                                    Address Line 2
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-9 col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="addressLine2"
                                                    id="addressLine2"
                                                    value={customerAddress.address_line2}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="city" className="col-form-label">
                                                    City
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="city"
                                                    id="city"
                                                    value={customerAddress.city}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="state" className="col-form-label padd-left-50">
                                                    State
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="state"
                                                    id="state"
                                                    value={customerAddress.state}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="country" className="col-form-label">
                                                    Country
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="country"
                                                    id="country"
                                                    value={customerAddress.country}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="postCode" className="col-form-label padd-left-50">
                                                    Post Code
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="postCode"
                                                    id="postCode"
                                                    value={customerAddress.postcode}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {(isDelivery === 'warehouse' || isDelivery === 'delivery') && (
                                    <>
                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="name" className="col-form-label">
                                                    Warehouse Name
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    value={deliveryAddress.name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="code" className="col-form-label padd-left-50">
                                                    Code
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="code"
                                                    id="code"
                                                    value={deliveryAddress.code}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="addressLine1" className="col-form-label">
                                                    Address Line 1
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-9 col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="addressLine1"
                                                    id="addressLine1"
                                                    value={deliveryAddress.address_line1}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label">
                                                    Address Line 2
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-9 col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="addressLine2"
                                                    id="addressLine2"
                                                    value={deliveryAddress.address_line2}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label">
                                                    City
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="city"
                                                    id="city"
                                                    value={deliveryAddress.city}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label padd-left-50">
                                                    State
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="state"
                                                    id="state"
                                                    value={deliveryAddress.state}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label">
                                                    Country
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="country"
                                                    id="country"
                                                    value={deliveryAddress.country}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <label htmlFor="desc" className="col-form-label padd-left-50">
                                                    Post Code
                                                </label>
                                            </div>
                                            <div className="col-6 col-lg-3 col-sm-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="postCode"
                                                    id="postCode"
                                                    value={deliveryAddress.postcode}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {isDelivery === 'collection' && (
                            <div className="col-6 col-lg-6 col-sm-6">
                                <div className="row mb-3">
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label padd-left-50">
                                            Collection Address
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3"></div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label">
                                            Address Line 1
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-9 col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="addressLine1"
                                            id="addressLine1"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label">
                                            Address Line 2
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-9 col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="addressLine2"
                                            id="addressLine2"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label">
                                            City
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            id="city"
                                        />
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label padd-left-50">
                                            State
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            id="state"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label">
                                            Country
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="country"
                                            id="country"
                                        />
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <label htmlFor="desc" className="col-form-label padd-left-50">
                                            Post Code
                                        </label>
                                    </div>
                                    <div className="col-6 col-lg-3 col-sm-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="postCode"
                                            id="postCode"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                     

                        <div className="col-6 col-lg-12 col-sm-12">
                            <hr />
                        </div>

                        <div className="col-6 col-lg-12 col-sm-12">
                            <Link
                                to="#"
                                className="popup-innerbtn-common right"
                                onClick={submitPurchaseOrderForm}
                                id="submitPurchaseOrderForm"
                            >
                                Place Purchase Order
                            </Link>
                        </div>
                    </div>
                  </div>






                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default CreatePO;
